import React from 'react';

import { AntiGas, HeatPumpYear, Labels } from '../icons';

import './our-asks-section.scss';

const OurAsksSection = ({ getText }: { getText: TextFunction.textContentFunction}) => (
  <div className="our-asks-section" id="demands">
    <div className="our-asks-section__inner">
      <h2>
        {
          getText(
            'Here’s how we get to sustainable and affordable heating:',
            { stringOnly: true },
          )
        }
      </h2>
      <div className="our-asks-section__asks">
        <div className="our-asks-section__ask">
          <div className="our-asks-section__ask-image">
            {AntiGas}
          </div>
          <div className="our-asks-section__description">
            <h3 className="our-asks-section__ask-title">
              {getText('Our demands Icon 1', { stringOnly: true })}
            </h3>
            {getText('What we are calling for 1')}
          </div>
        </div>
        <div className="our-asks-section__ask">
          <div className="our-asks-section__ask-image">
            {HeatPumpYear}
          </div>
          <div className="our-asks-section__description">
            <h3 className="our-asks-section__ask-title">
              {getText('Our demands Icon 2', { stringOnly: true })}
            </h3>
            {getText('What we are calling for 2')}
          </div>
        </div>
        <div className="our-asks-section__ask">
          <div className="our-asks-section__ask-image">
            {Labels}
          </div>
          <div className="our-asks-section__description">
            <h3 className="our-asks-section__ask-title">
              {getText('Our demands Icon 3', { stringOnly: true })}
            </h3>
            {getText('What we are calling for 3')}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OurAsksSection;
