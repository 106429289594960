import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { CountryProvider } from '../components/country-context';
import { TabProvider } from '../components/tabbed-context';

import BansMap from '../components/bans-map';
import BoilerAnimation from '../components/boiler-animation';
import ExploringTheData from '../components/exploring-the-data';
import FinalSection from '../components/final-section';
import Footer from '../components/footer';
import GasImportsDiagram from '../components/gas-imports-diagram';
import getTextFunctionFromData from '../utils/text';
import HeroAnimation from '../components/hero-animation/hero-animation';
import HeroSection from '../components/hero-section';
import Layout from '../components/layout';
import Menu from '../components/menu';
import OurAsksSection from '../components/our-asks-section';
import ReadMore from '../components/read-more';
import SEO from '../components/seo';
import ThankYou from '../components/thank-you';
import VersusMap from '../components/versus-map';
import WhyGasFree from '../components/why-gas-free';

import type { PartnerEntry } from '../components/partner-list';

import './main.scss';

interface IndexPageProps {
  readonly data: {
    readonly django: {
      readonly contentsForLanguage: TextFunction.titleBodyPair[]
      readonly allPartners: PartnerEntry[]
      readonly commentsForLanguage: any[]
    }
  }
}

const IndexPage = (
  { data: { django: { contentsForLanguage, allPartners, commentsForLanguage } } }: IndexPageProps,
) => {
  const getText = getTextFunctionFromData(contentsForLanguage);
  return (
    <Layout>
      <SEO
        title={getText('SEO-title', { stringOnly: true })}
        description={getText('SEO-description', { stringOnly: true })}
        image="https://betterwithoutboilers.com/images/og.jpg"
      />
      <Menu getText={getText} />
      <HeroSection getText={getText} introText />
      <HeroAnimation />
      <WhyGasFree text={getText('Why are we better without boilers points')} getText={getText} />
      <OurAsksSection getText={getText} />
      <ExploringTheData getText={getText} />
      <TabProvider>
        <CountryProvider>
          <BansMap getText={getText} data={commentsForLanguage} />
        </CountryProvider>
      </TabProvider>
      <hr />
      <TabProvider>
        <CountryProvider>
          <VersusMap getText={getText} />
        </CountryProvider>
      </TabProvider>
      <hr />
      <CountryProvider>
        <GasImportsDiagram getText={getText} />
      </CountryProvider>
      <WhyGasFree text={getText('Why are we better without boilers?')} getText={getText}>
        <div className="read-more-list">
          {getText('Why are we better without boilers?')}
          <div>
            <ReadMore
              readMoreLabel={getText('Read more', { stringOnly: true })}
              title={getText('Read More Title 1', { stringOnly: true })}
              content={getText('Homes without boilers mean energy independence')}
              image={(
                <StaticImage
                  src="../images/read-more/BWB_photo-edits_energy-independance.jpg"
                  alt={getText('ALT-BWB_photo-edits_energy-independance.jpg', { stringOnly: true })}
                  width={912}
                  height={609}
                  quality={98}
                />
              )}
            />
            <ReadMore
              readMoreLabel={getText('Read more', { stringOnly: true })}
              title={getText('Read More Title 2', { stringOnly: true })}
              content={getText('Homes without boilers help empty Putin\'s coffers')}
              image={(
                <StaticImage
                  src="../images/read-more/BWB_photo-edits_Putin.jpg"
                  alt={getText('ALT-BWB_photo-edits_Putin.jpg', { stringOnly: true })}
                  width={912}
                  height={609}
                  quality={98}
                />
              )}
            />
            <ReadMore
              readMoreLabel={getText('Read more', { stringOnly: true })}
              title={getText('Read More Title 3', { stringOnly: true })}
              content={getText('Homes without boilers make us breath better')}
              image={(
                <StaticImage
                  src="../images/read-more/BWB_photo-edits_healthier.jpg"
                  alt={getText('ALT-BWB_photo-edits_healthier.jpg', { stringOnly: true })}
                  width={912}
                  height={609}
                  quality={98}
                />
              )}
            />
            <ReadMore
              readMoreLabel={getText('Read more', { stringOnly: true })}
              title={getText('Read More Title 4', { stringOnly: true })}
              content={getText('Homes without boilers fight the cost-of-living crisis')}
              image={(
                <StaticImage
                  src="../images/read-more/BWB_photo-edits_Cost-of-living.jpg"
                  alt={getText('ALT-BWB_photo-edits_Cost-of-living.jpg', { stringOnly: true })}
                  width={912}
                  height={609}
                  quality={98}
                />
              )}
            />
            <ReadMore
              readMoreLabel={getText('Read more', { stringOnly: true })}
              title={getText('Read More Title 5', { stringOnly: true })}
              content={getText('Homes without boilers help save the planet')}
              image={(
                <StaticImage
                  src="../images/read-more/BWB_photo-edits_planet.jpg"
                  alt={getText('ALT-BWB_photo-edits_planet.jpg', { stringOnly: true })}
                  width={912}
                  height={609}
                  quality={97}
                />
              )}
            />
            <ReadMore
              readMoreLabel={getText('Read more', { stringOnly: true })}
              title={getText('Read More Title 6', { stringOnly: true })}
              content={getText('Homes without boilers will boost the economy')}
              image={(
                <StaticImage
                  src="../images/read-more/BWB_photo edits_economy.jpg"
                  alt={getText('ALT-BWB_photo edits_economy.jpg', { stringOnly: true })}
                  width={912}
                  height={609}
                  quality={98}
                />
              )}
            />
          </div>
        </div>
      </WhyGasFree>
      <BoilerAnimation getText={getText} />
      <FinalSection getText={getText} partners={allPartners} />
      <ThankYou getText={getText} />
      <Footer getText={getText} />
    </Layout>
  );
};
export default IndexPage;

export const pageQuery = graphql`
query TranslatedContent($language: Int!) {
  django {
    contentsForLanguage(lang: $language) {
      title
      body
    }
    allPartners {
      name
      partnerType
      url
    }
    commentsForLanguage(lang: $language) {
      gasAllAbove
      countryId {
        country
        gasAll
        gasNew
        oilAll
        oilNew
        isSubsidized
      }
      gasNewAbove
      gasAllBelow
      gasNewBelow
      oilAllAbove
      oilAllBelow
      oilNewAbove
      oilNewBelow
    }
  }
}
`;
