import React from 'react';

import './diagram-wrapper.scss';

interface DiagramWrapperProps {
  children: React.ReactNode;
  title: string;
  description: React.ReactNode;
  orientation?: 'horizontal' | 'vertical';
}

const DiagramWrapper = ({
  children, title, description, orientation,
}: DiagramWrapperProps) => (
  <div className={`diagram-wrapper diagram-wrapper--${orientation}`}>
    <div className="diagram-wrapper__explainer">
      <h3 className="diagram-wrapper__title">{title}</h3>
      <div className="diagram-wrapper__description">{description}</div>
    </div>
    {children}
  </div>
);

DiagramWrapper.defaultProps = {
  orientation: 'horizontal',
};

export default DiagramWrapper;
