import React, { createContext, useState, ReactNode } from 'react';

const CountryContext = createContext({
  country: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCountry: (country: string) => {},
});

export const CountryProvider = ({ children }: { children: ReactNode }) => {
  const [country, setCountry] = useState('');

  return (
    <CountryContext.Provider value={{ country, setCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export default CountryContext;
