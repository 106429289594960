import React, { useState } from 'react';

import './why-gas-free.scss';

interface WhyGasFreeProps {
  text: React.ReactNode;
  children?: React.ReactNode;
  getText: TextFunction.textContentFunction;
}

const WhyGasFree = ({ text, children, getText }: WhyGasFreeProps) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="why-gas-free" id={children ? 'better-without-boilers' : 'bwb'}>
      <div className="why-gas-free__inner">
        <div className="why-gas-free__content">
          <h2 className="why-gas-free__title">
            {getText('We are we better without boilers!', { stringOnly: true })}
          </h2>
          <div className="why-gas-free__text">
            {text}
          </div>
          {children && !isVisible ? (
            <div className="why-gas-free__read-more">
              <button
                type="button"
                className="why-gas-free__read-more-button"
                onClick={() => setIsVisible(true)}
              >
                {getText('Read more', { stringOnly: true })}
              </button>
            </div>
          ) : ''}
        </div>
      </div>
      {children && isVisible ? (
        <div className="why-gas-free__read-more-content">
          {children}
        </div>
      ) : ''}
    </div>
  );
};

WhyGasFree.defaultProps = {
  children: null,
};

export default WhyGasFree;
