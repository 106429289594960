import React from 'react';

import './icons.scss';

export const AntiGas = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326 326" className="anti-gas-icon">
    <g transform="translate(-90.3 -1739.3)">
      <circle cx="163" cy="163" r="163" fill="#FCCF31" transform="translate(90.3 1739.3)" />
      <g>
        <path className="anti-gas-icon__flame" fill="#fff" d="M246.5 1816.8s-53 74.2-53 126.6c0 26.8 26.4 48.6 59 48.6s58.8-21.8 58.8-48.6c0-61.6-64.8-126.6-64.8-126.6Zm18.5 166a17.4 17.4 0 0 0 4.3-15.1c-2-10.1-16-35.3-16-35.3s-19 13.7-19 35.3c0 5.6.5 10.7 2.6 14.3-15.8-5.1-27-17.9-27-32.7 0-38 36.6-91.7 36.6-91.7s48.5 47.1 48.5 91.7c0 15.8-12.6 29.1-30 33.6Z" />
        <path d="M340 1800a136.3 136.3 0 1 0 16.8 192 136.2 136.2 0 0 0-16.8-192Zm-169.6 184.4A114.6 114.6 0 0 1 317 1809.7Zm17.5 14.7 146.6-174.8a114.6 114.6 0 0 1-146.6 174.8Z" />
      </g>
    </g>
  </svg>
);

export const AntiOil = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <circle cx="200" cy="200" r="200" fill="#E81CFF" />
    <path d="M237.4 298.3H104.9V124.6h132.6v173.7zm-125.6-6.9h118.6V131.5H111.8v159.9z" fill="#FFF" stroke="#FFF" strokeWidth="5.67" strokeMiterlimit="10" />
    <path fill="#FFF" stroke="#FFF" strokeWidth="5.67" strokeMiterlimit="10" d="M97.3 155.8H245v7H97.3zM97.3 208.8H245v7H97.3zM97.3 261.9H245v7H97.3z" />
    <path d="M188.8 121.6s-4.1 9.8-.5 14.5c3.6 4.8 8.9 1.7 12.4.4s.1 7 1.3 8.6 10.6 3.4 12.4-2.7-3.3-10-2.9-12.9c.4-2.9 4 2.7 7.9.3 3.9-2.4.3-8.1.3-8.1" />
    <g>
      <path d="M325.7 222.9c0 15.8-15.5 28.6-34.6 28.6s-34.6-12.8-34.6-28.6c0-30.8 34.6-74.5 34.6-74.5s34.6 38.3 34.6 74.5z" />
      <path d="M282.7 240.7c-2.5 2.7-9.6-.3-14-4.3-3.8-3.5-5.6-8.8-4.5-13.9.9-2.9 21.8 17.7 18.5 18.2z" fill="#FFF" />
    </g>
    <g>
      <path d="M167.8 103c0 5.8-5.7 10.5-12.7 10.5s-12.7-4.7-12.7-10.5c0-11.3 12.7-27.4 12.7-27.4s12.7 14.1 12.7 27.4z" />
      <path d="M152 109.5c-.9 1-3.5-.1-5.1-1.6-1.4-1.3-2-3.2-1.7-5.1.3-1.1 8 6.5 6.8 6.7z" fill="#FFF" />
    </g>
    <g><path d="M308.4 70.8C237 11 130.7 20.3 70.8 91.6 11 163 20.3 269.3 91.6 329.2c71.3 59.9 177.7 50.5 237.5-20.8 59.9-71.3 50.6-177.7-20.7-237.6 0 .1 0 .1 0 0zM87.1 105.2c48.7-58 133.4-69.7 196-27L94.5 303a147.52 147.52 0 0 1-7.4-197.8zm29.8 216.6L305.5 97.1c56.9 58.3 55.7 151.6-2.6 208.5-50.2 48.9-128 55.7-186 16.2z" /></g>
  </svg>
);

export const BoilerSubsidies = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <circle cx="200" cy="200" r="200" fill="#F55555" />
    <path d="M200 29.3a172.1 172.1 0 1 0 172.1 172.1c0-95.1-77.1-172.1-172.1-172.1zm0 322.5a150.5 150.5 0 1 1 0-301 150.5 150.5 0 0 1 0 301z" />
    <path d="M123.5 94.2h153v168.4h-153z" />
    <circle cx="201.8" cy="142.3" r="30.3" fill="#FFF" />
    <path d="M219.5 159.5a18 18 0 0 1-35.4 0c0-15.8 15.9-38.2 15.9-38.2s19.5 19.7 19.5 38.2z" />
    <path d="M214.6 161.3a13.1 13.1 0 0 1-15.3 10.4 13 13 0 0 1-10.4-10.4c0-11.4 11-27.6 11-27.6s14.7 14.2 14.7 27.6z" fill="#FFF" />
    <path d="M206.9 166.8a5.1 5.1 0 0 1-3.9 6h-2.1c-4 0-4.5-2.7-4.5-6 .3-4.2 2.4-8.1 5.7-10.6 1.9 3.4 3.5 6.9 4.8 10.6z" />
    <g><path fill="#FFF" d="M138 209.8h127.6v41.4H138z" /></g>
    <g><path d="M176 42.1h48.1v52.1H176z" /></g>
    <g>
      <ellipse cx="224" cy="310.8" rx="49.6" ry="15" />
      <path d="M224 326.5c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7-25.9 15.7-50.4 15.7zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2s3.4 5.5 13 8.2c9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2s-3.4-5.5-13-8.2c-9-2.5-21-4-33.9-4z" fill="#FFF" />
      <ellipse cx="224" cy="299.1" rx="49.6" ry="15" />
      <path d="M224 314.8c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7-25.9 15.7-50.4 15.7zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2s3.4 5.5 13 8.2c9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2s-3.4-5.5-13-8.2c-9-2.5-21-4-33.9-4z" fill="#FFF" />
      <ellipse cx="224" cy="287.4" rx="49.6" ry="15" />
      <path d="M224 303.1c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7-25.9 15.7-50.4 15.7zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2s3.4 5.5 13 8.2c9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2s-3.4-5.5-13-8.2c-9-2.5-21-4-33.9-4z" fill="#FFF" />
      <ellipse cx="224" cy="275.7" rx="49.6" ry="15" />
      <path d="M224 291.4c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7-25.9 15.7-50.4 15.7zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2 0 2.2 3.4 5.5 13 8.2 9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2 0-2.2-3.4-5.5-13-8.2-9-2.5-21-4-33.9-4z" fill="#FFF" />
      <g>
        <ellipse cx="224" cy="264" rx="49.6" ry="15" />
        <path d="M224 279.7c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7-25.9 15.7-50.4 15.7zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2s3.4 5.5 13 8.2c9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2s-3.4-5.5-13-8.2c-9-2.6-21-4-33.9-4z" fill="#FFF" />
      </g>
      <g>
        <ellipse cx="224" cy="252.2" rx="49.6" ry="15" />
        <path d="M224 268c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7S248.5 268 224 268zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2s3.4 5.5 13 8.2c9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2s-3.4-5.5-13-8.2c-9-2.6-21-4-33.9-4z" fill="#FFF" />
      </g>
      <g>
        <ellipse cx="224" cy="240.5" rx="49.6" ry="15" />
        <path d="M224 256.3c-24.4 0-50.4-5.5-50.4-15.7s26-15.7 50.4-15.7 50.4 5.5 50.4 15.7-25.9 15.7-50.4 15.7zm0-28c-12.8 0-24.9 1.4-33.9 4-9.6 2.8-13 6-13 8.2s3.4 5.5 13 8.2c9 2.6 21.1 4 33.9 4s24.9-1.4 33.9-4c9.6-2.8 13-6 13-8.2s-3.4-5.5-13-8.2c-9-2.6-21-4-33.9-4z" fill="#FFF" />
      </g>
    </g>
  </svg>
);

export const HeatPumpYear = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <circle cx="286.8" cy="2425" r="200" fill="#55F25B" transform="translate(-86.8 -2225)" />
    <path d="M340 301.5H60.5V107.1H340v194.4zM81 281.1h238.5V127.4H81v153.7z" fill="#FFF" />
    <g fill="#FFF">
      <path d="M70.8 117.3h259v35.1h-259z" />
      <path d="M340 162.5H60.5v-55.4H340v55.4zM81 142.2h238.5v-14.8H81v14.8z" />
    </g>
    <path fill="#FFF" d="M110.4 82.6h20.3V132h-20.3zM267.1 82.6h20.3V132h-20.3z" />
    <path className="heat-pump-year__year" d="M165.4 256.5c2.3-3 5.5-6.1 5.5-9.6 0-1.7 0-2.3-1-2.3-.8 0-1 .5-.9 3.3 0 .6-.2.7-.7.7H164c-.5 0-1-.2-1-.9 0-5.6 2.1-8.4 7-8.4 4.2 0 7.5 1.6 7.5 7.6 0 5.6-4.2 9-6.8 12.4h5.8c.4 0 .7.3.7.7v4c0 .4-.3.7-.7.7h-12.7a.7.7 0 0 1-.7-.7v-2.5c0-1.6 1.1-3.3 2.4-5zM181.4 252.1c0-11 3.4-12.8 8-12.8 4.5 0 8 1.9 8 12.8 0 11-3.4 12.9-8 12.9s-8-1.9-8-12.9zm9 0c0-6.5 0-7.5-1-7.5s-.9 1-.9 7.5 0 7.5 1 7.5.8-1 .8-7.5zM204 256.5c2.3-3 5.5-6.1 5.5-9.6 0-1.7 0-2.3-1-2.3-.8 0-1 .5-.9 3.3 0 .6-.2.7-.7.7h-4.4c-.5 0-1-.2-1-.9 0-5.6 2.1-8.4 7-8.4 4.2 0 7.5 1.6 7.5 7.6 0 5.6-4.2 9-6.8 12.4h5.8c.4 0 .7.3.7.7v4c0 .4-.3.7-.7.7h-12.8a.7.7 0 0 1-.7-.7v-2.5c0-1.6 1.1-3.3 2.5-5zM229.6 248.2c4 0 5.3 3 5.3 7.8 0 6.5-2.6 8.9-8 8.9-4.9 0-7-2.2-7-7 0-.7.5-.9 1-.9h4.4c.6 0 .7.4.7 1.2 0 1.3.2 1.8.8 1.8 1 0 1.1-.3 1.1-3.9 0-2.6-.2-3-1-3-.7 0-.9.4-.9.8 0 .7-.2 1.2-.7 1.2h-4c-.5 0-1-.2-1-.9v-13.9c0-.4.3-.7.7-.7h12.6c.4 0 .7.3.7.7v4c0 .4-.3.7-.7.7h-6.5v3.9c.6-.4 1.6-.7 2.5-.7z" />
    <g>
      <path d="M117 124.4h164v100.8H117z" />
      <path d="M130.7 219h16.9v16.9h-17zM248.8 219h16.9v16.9h-17z" />
      <circle cx="69.9" cy="71.9" r="34.4" fill="#FFF" transform="translate(95 101)" />
      <path className="heat-pump-year__fan" d="M166 182.8c0 2.9-.3 5.9-1.1 8.7-.5 2-1.8 4-3.5 5.3a7.5 7.5 0 0 1-5.3 1.2 20 20 0 0 1-14.8-10c-1.8-2.7-3-5.8-3.5-9a9 9 0 0 1 .4-5l.8-1.1c.3-.3.5-.3.7 0 1.9 2.2 4.4 3.9 7.3 4.8 3.9 1.2 8 1 11.8-.5l.2-.1c.5-.3 1-.2 1.4.2a6.8 6.8 0 0 0 4.9 1.8c.8 0 .8 0 .8.8-.2 1-.1 1.9-.1 2.9zM192.5 175c0 4.6-1.3 9-3.6 12.9-.8 1.4-2 2.6-3.2 3.6-.6.5-1.4.8-2.2.8-.5 0-.6-.1-.6-.6.2-6.4-3.3-12.3-9-15.2l-2-1c-.3-.1-.3-.2-.2-.6 1.2-2.5.9-5.4-.7-7.6-.6-1-.6-1 .2-1.6 2-2.2 4.5-4 7.2-5.3 1.5-.8 3.3-1 5-.8a8 8 0 0 1 5 3.2c2.4 2.9 3.8 6.5 4 10.2v2zM144.4 160.6c0-2 .7-4 1.9-5.6 2.4-3.6 5.8-6.3 9.9-7.7 3-1.2 6.4-1.8 9.8-1.6 2.3 0 4.5.5 6.6 1.5.9.4 1.6 1 2 1.9.4.5.3.6-.2.8A16.1 16.1 0 0 0 164 163c-.1.9-.4 1.4-1.4 1.7a7.2 7.2 0 0 0-5.1 6.4c0 .4-.1.6-.5.4-3.4-1-6.6-2.7-9.3-4.8a9 9 0 0 1-3.3-5.2l-.1-1zM170.4 171.7a5.4 5.4 0 1 1 0-.1z" />
      <path fill="#FFF" d="M218.3 148.4h50.6v3.4h-50.6zM218.3 159.9h50.6v3.4h-50.6z" />
      <g><path fill="#FFF" d="M218.3 171.3h50.6v3.4h-50.6z" /></g>
      <g><path fill="#FFF" d="M218.3 182.8h50.6v3.4h-50.6z" /></g>
      <g><path fill="#FFF" d="M218.3 194.2h50.6v3.4h-50.6z" /></g>
    </g>
    <g><path d="M291.6 171.9H324v3.4h-32.4zM291.1 152l32.4-10.3 1 3.2-32.3 10.4zM291.1 195.1l1-3.2 32.4 10.3-1 3.3z" /></g>
    <g><path d="M76.3 171.9h32.4v3.4H76.3zM75.8 202.2 108 192l1 3.2-32.3 10.4zM75.7 144.9l1-3.2 32.5 10.3-1 3.2z" /></g>
  </svg>
);

export const Labels = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <circle cx="200" cy="200" r="200" fill="#F55555" />
    <path d="M143.8 93.6c0 .3-.2.4-.5.4h-3.1c-.2 0-.4-.2-.4-.4s.1-.6.1-.9l2.9-14.1c0-.3.2-.4.5-.4h3.5c.3 0 .4.1.5.4l2.9 14.1c0 .3.1.6.1.9 0 .3-.2.4-.4.4h-3.1c-.3 0-.5-.1-.5-.4l-.2-2.1h-2l-.3 2.1zm1.7-6.5c-.1-.6-.3-2.9-.3-3.2 0-.2 0-.3-.1-.3s-.1.1-.1.3c0 .2-.2 2.6-.3 3.2l-.2 1.6h1.3l-.3-1.6zM167.1 129.3v-14.9c0-.3.2-.5.5-.5h5.3c2.4 0 3.5 1.6 3.5 4 0 1.5-.5 3.1-1.8 3.3 1.7.5 2.3 2 2.3 3.9 0 2.7-1.4 4.5-3.9 4.5h-5.4c-.3.2-.5 0-.5-.3zm4.7-9.2c.7 0 .8-.2.8-1.7 0-1.4-.1-1.7-.8-1.7h-.4v3.4h.4zm0 6.9c.7 0 .8-.2.8-2s-.1-2-.8-2h-.4v4.1h.4zM184.8 157.6c0-6.1 1.7-8 5.1-8 2.5 0 4.4 1.4 4.4 5.6 0 .4-.3.6-.6.6h-2.8c-.3 0-.5-.3-.4-.7.1-1.6 0-2.1-.6-2.1-.7 0-.6.5-.6 4.7s-.1 4.7.6 4.7c.6 0 .6-.5.6-2.1 0-.5.1-.7.4-.7h2.8c.3 0 .6.2.6.6 0 4.3-1.9 5.6-4.4 5.6-3.4-.2-5.1-2.1-5.1-8.2zM204.6 200.9V186c0-.3.2-.5.5-.5h4.6c2.6 0 4.9 1.4 4.9 7.9s-2.2 7.9-4.9 7.9h-4.6c-.3 0-.5-.1-.5-.4zm4.8-2.8c.7 0 .8-.2.8-4.7 0-4.4-.1-4.7-.8-4.7h-.4v9.4h.4zM227.7 236.9V222c0-.3.2-.5.5-.5h6c.3 0 .5.2.5.5v2.5c0 .3-.2.5-.5.5h-2.1v2.3h1.8c.3 0 .5.2.5.5v2.5c0 .3-.2.5-.5.5h-1.8v3.1h2.2c.3 0 .5.2.5.5v2.5c0 .3-.2.5-.5.5h-6.1c-.3-.1-.5-.3-.5-.5zM257.7 272.5v-14.9c0-.3.2-.5.5-.5h6c.3 0 .5.2.5.5v2.5c0 .3-.2.5-.5.5h-2.1v2.3h1.8c.3 0 .5.2.5.5v2.5c0 .3-.2.5-.5.5h-1.8v6.1c0 .3-.2.5-.5.5h-3.5c-.2-.1-.4-.3-.4-.5zM285.6 301.1c0-6.1 1.7-8 5.1-8 2.5 0 4.4 1.4 4.4 5.6 0 .4-.3.6-.6.6h-2.8c-.3 0-.5-.3-.4-.7.1-1.6 0-2.1-.6-2.1-.7 0-.6.5-.6 4.7 0 4.1 0 5 1 5 .5 0 .9-.3.9-1.3v-.3h-.5c-.3 0-.5-.2-.5-.5v-2.4c0-.3.2-.5.5-.5h3.1c.3 0 .5.2.5.5v5.4c0 1-.3 1.2-.9 1.4-.8.3-2.1.8-3.6.8-3.3-.1-5-2-5-8.2z" />
    <path d="M200.92 182.87h-106v-49.7h106l20.6 24.9-20.6 24.8zm-92-14h85.4l9-10.9-9-10.9h-85.4v21.8z" />
    <path d="M220.22 218.57H94.92v-49.7h125.3l19.6 24.9-19.6 24.8zm-111.3-14h104.6l8.6-10.9-8.6-10.9h-104.6v21.8z" />
    <path d="M244.22 254.37H94.92v-49.7h149.3l16.1 24.8-16.1 24.9zm-135.3-14h127.7l7-10.9-7-10.9h-127.7v21.8z" />
    <path d="M272.12 290.07H94.92v-49.7h177.2l18.7 24.9-18.7 24.8zm-163.2-14h156.3l8.2-10.9-8.2-10.9h-156.3v21.8z" />
    <path d="M303.52 326.47H94.92v-49.7h208.6l18.6 24.9-18.6 24.8zm-194.6-14h187.6l8.1-10.9-8.1-10.9h-187.6v21.8zM184.52 147.07h-89.6v-49.7h89.6l17.4 24.9-17.4 24.8zm-75.6-14h68.3l7.6-10.9-7.6-10.9h-68.3v21.8z" />
    <path d="M160.32 111.37h-65.4v-49.7h65.4l12.7 24.9-12.7 24.8zm-51.4-14h42.8l5.6-10.9-5.6-10.9h-42.8v21.8z" />
    <g className="labels-icon__pen">
      <path d="m224.8 179.7 106.6-71.4 18.2 27.2L243 206.9l-29.3.6 11.1-27.8z" fill="#FFF" />
      <g transform="translate(225.1 2771.2)">
        <defs><path id="a" d="m-.3-2591.5 106.6-71.4 18.2 27.2-106.6 71.4-29.3.5z" /></defs>
        <clipPath id="b"><use xlinkHref="#a" overflow="visible" /></clipPath>
        <g clipPath="url(#b)">
          <path fill="#F55555" d="m84.05-2674.19 14.55-9.73 35.2 52.6-14.54 9.73z" />
          <path fill="#40C9FF" d="m21.6-2559.6-24.1-36 2.9-2 22.2 33.2 83.8-56.1-22.2-33.1 2.9-2 24.1 36.1z" />
          <path fill="none" d="m-41.43-2562.16 32.33-21.63 23.14 34.57-32.33 21.63z" />
          <g fill="#F55555">
            <path d="m-2.3-2596.6 86.7-58 23.2 34.6-87 62.1" />
            <path d="m22.5-2555.1-4.1-5.7 84.3-60.1-19.3-28.9-83.8 56.1-3.9-5.8 89.6-60 27 40.4z" />
          </g>
        </g>
      </g>
      <path d="m211.1 209.2 12.3-30.7 108.5-72.6 20.2 30.1-108.5 72.6-32.5.6zm15.1-28.4-9.9 24.8 26.1-.5L347.1 135l-16.3-24.3-104.6 70.1z" fill="#FFF" />
    </g>
  </svg>
);

export const NoOil = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 79">
    <g transform="translate(-.3 -.3)">
      <circle cx="39.5" cy="39.5" r="39.5" fill="#e81cff" transform="translate(.3 .3)" />
    </g>
    <path fill="#fff" d="M47 58.9H20.8V24.7h26Zm-24.8-1.4h23.4V26.1H22.2Z" />
    <path fill="#fff" d="M19.4 30.9h29v1.4h-29z" />
    <path fill="#fff" d="M19.4 41.3h29v1.4h-29z" />
    <path fill="#fff" d="M19.4 51.7h29v1.4h-29z" />
    <path d="M37.4 24.5s-.8 2-.1 2.9 1.7.3 2.4 0 0 1.4.3 1.7 2 .7 2.4-.5-.7-2-.6-2.5.8.5 1.6 0 0-1.6 0-1.6" />
    <path d="M64.3 44c0 3.2-3 5.7-6.8 5.7s-6.8-2.5-6.8-5.6c0-6 6.8-14.7 6.8-14.7s6.8 7.6 6.8 14.7Z" />
    <path fill="#fff" d="M55.8 47.6c-.5.5-1.9 0-2.7-.9a3 3 0 0 1-1-2.7c.2-.6 4.4 3.5 3.7 3.6Z" />
    <path d="M33.2 20.5a2.5 2.5 0 0 1-5 0c0-2.2 2.5-5.4 2.5-5.4s2.5 2.8 2.5 5.4Z" />
    <path fill="#fff" d="M30.1 21.8c-.1.2-.7 0-1-.3a1 1 0 0 1-.3-1c0-.2 1.6 1.3 1.3 1.3Z" />
    <path d="M60.9 14.2a33.1 33.1 0 1 0 4 46.7 33.1 33.1 0 0 0-4-46.7Zm-43.5 6.7a29 29 0 0 1 38.5-5.3l-37 44.2a29 29 0 0 1-1.5-38.9Zm5.8 42.6 37-44.2a29 29 0 0 1-37 44.2Z" />
  </svg>
);

export const HeatPump = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <path d="M200 400c-27 0-53.2-5.3-77.8-15.7-11.8-5-23.3-11.2-34-18.4-10.6-7.2-20.6-15.4-29.6-24.4a199.27 199.27 0 0 1-42.8-63.6C5.3 253.2 0 227 0 200s5.3-53.2 15.7-77.8c5-11.8 11.2-23.3 18.4-34 7.2-10.6 15.4-20.6 24.4-29.6a199.27 199.27 0 0 1 63.6-42.8C146.8 5.3 173 0 200 0s53.2 5.3 77.8 15.7c11.8 5 23.3 11.2 34 18.4 10.6 7.2 20.6 15.4 29.6 24.4a199.27 199.27 0 0 1 42.8 63.6C394.7 146.8 400 173 400 200s-5.3 53.2-15.7 77.8c-5 11.8-11.2 23.3-18.4 34-7.2 10.6-15.4 20.6-24.4 29.6a199.27 199.27 0 0 1-63.6 42.8A197.97 197.97 0 0 1 200 400z" fill="#55F25B" />
    <path fill="#FFF" d="M102.4 139.4h200.1v116H102.4z" />
    <path d="M199.2 31.4C106 31.4 30.4 107 30.4 200.2 30.4 293.4 106 369 199.2 369S368 293.5 368 200.2c0-93.2-75.6-168.8-168.8-168.8zm0 316.4c-81.5 0-147.6-66.1-147.6-147.6S117.7 52.6 199.2 52.6s147.6 66.1 147.6 147.6-66.1 147.6-147.6 147.6z" />
    <path d="M156.53 209.53c.1 3.8-.4 7.7-1.5 11.4-.7 2.8-2.4 5.2-4.6 6.9a9.9 9.9 0 0 1-6.9 1.6c-8.7-1.1-14.8-5.9-19.4-13-2.3-3.5-3.9-7.6-4.6-11.8a12 12 0 0 1 .5-6.5c.2-.6.6-1.1 1-1.6.3-.4.6-.4 1 0 2.5 2.9 5.8 5.1 9.5 6.3 5 1.6 10.5 1.4 15.4-.6.1 0 .2-.1.3-.1.6-.4 1.4-.3 1.8.3 1.8 1.6 4.1 2.5 6.4 2.4 1 0 1 0 1 1 0 1.3.1 2.5.1 3.7zM191.23 199.43c0 6-1.7 11.8-4.8 16.9-1.1 1.8-2.5 3.4-4.2 4.7-.8.6-1.9 1-2.9 1.1-.6 0-.8-.2-.8-.8.3-8.3-4.3-16.1-11.7-19.9-.8-.4-1.6-.8-2.5-1.2-.5-.2-.6-.4-.4-1 1.6-3.2 1.2-7-.9-9.9-.8-1.1-.8-1.1.2-2.1 2.7-2.9 5.9-5.3 9.4-7 2-1 4.3-1.4 6.5-1.1 2.7.5 5.1 2 6.7 4.2 3 3.8 4.8 8.5 5.2 13.3.2 1 .2 1.9.2 2.8zM128.23 180.53c.1-2.6 1-5.2 2.5-7.4 3.1-4.7 7.6-8.2 13-10.1 4.1-1.6 8.4-2.3 12.8-2.2 3 0 5.9.7 8.6 2 1.2.5 2.1 1.4 2.8 2.5.4.7.3.8-.4 1.1-7.3 2.8-12.5 9.5-13.4 17.3-.2 1.2-.5 1.8-1.8 2.2a9.3 9.3 0 0 0-6.7 8.4c0 .5-.1.8-.7.6-4.4-1.3-8.6-3.5-12.2-6.3a11.79 11.79 0 0 1-4.5-8.1z" />
    <path d="M162.33 195.13c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7c3.8 0 7 3 7 6.9v.1z" />
    <path d="M92.4 133.14v131.9h17.9v14.1h22.1v-14.1h132.5v14.1H287v-14.1h20v-131.9H92.4zm62.9 108.7a45 45 0 1 1 0-90 45 45 0 0 1 0 90zm135.9-13.3h-66.3v-3.3h66.3v3.3zm0-15h-66.3v-3.3h66.3v3.3zm0-15h-66.3v-3.3h66.3v3.3zm0-15h-66.3v-3.3h66.3v3.3zm0-15h-66.3v-3.3h66.3v3.3z" />
  </svg>
);

export const Boiler = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <g transform="translate(-655.9 -5760.9)">
      <path d="M855.9 5760.9c110.5 0 200 89.5 200 200s-89.5 200-200 200-200-89.5-200-200 89.6-200 200-200z" fill="#F55555" />
      <path d="M855.9 5791.6c-93.5 0-169.3 75.8-169.3 169.3s75.8 169.3 169.3 169.3 169.3-75.8 169.3-169.3c0-93.5-75.8-169.3-169.3-169.3zm0 317.3c-81.7 0-148-66.3-148-148s66.3-148 148-148 148 66.3 148 148-66.2 148-148 148z" />
      <path d="M773.1 5884.5h165.5v182.2H773.1z" />
      <circle cx="857.8" cy="5922.6" r="22.2" fill="#FFF" />
      <path d="M870.82 5935.17a13.23 13.23 0 0 1-26 0c0-11.6 11.7-28 11.7-28s14.3 14.4 14.3 28z" />
      <path d="M867.26 5936.47c-1 5.2-6.1 8.6-11.3 7.5a9.5 9.5 0 0 1-7.5-7.5 47.6 47.6 0 0 1 8.1-20.2c-.1 0 10.7 10.4 10.7 20.2z" fill="#FFF" />
      <path d="M861.52 5940.6a3.75 3.75 0 0 1-4.5 4.4c-3 0-3.3-2-3.3-4.4.2-3.1 1.7-5.9 4.2-7.8 1.5 2.5 2.7 5.1 3.6 7.8z" />
      <path fill="#FFF" d="M788.8 6009.5h138.1v44.7H788.8z" />
      <circle cx="132.8" cy="229.9" r="9.6" transform="translate(675.81 5801.69)" />
      <path d="m812.52 6025.86 4.5-3.98 2.32 2.63-4.5 3.97zM885.3 6021.88h31.3v3.5h-31.3zM885.3 6030.13h31.3v3.5h-31.3zM885.3 6038.28h31.3v3.5h-31.3zM838.26 6021.88h31.3v3.5h-31.3zM838.26 6030.13h31.3v3.5h-31.3zM838.26 6038.28h31.3v3.5h-31.3z" />
      <path stroke="#000" strokeWidth=".34" strokeMiterlimit="10" d="M831.8 5806.5h48.3v81.2h-48.3zM795.7 6062.8h8.1v43.8h-8.1zM818.1 6062.8h8.1v43.8h-8.1z" />
      <g><path d="M789.5 6077.8h18.9v3.5h-18.9z" /></g>
      <g><path d="M812.7 6077.8h18.9v3.5h-18.9z" /></g>
    </g>
  </svg>
);

export const AntiBoiler = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <g transform="translate(-655.9 -5760.9)">
      <path d="M855.9 5760.9c110.5 0 200 89.5 200 200s-89.5 200-200 200-200-89.5-200-200 89.6-200 200-200z" fill="#F55555" />
      <path d="M855.9 5791.6c-93.5 0-169.3 75.8-169.3 169.3s75.8 169.3 169.3 169.3 169.3-75.8 169.3-169.3c0-93.5-75.8-169.3-169.3-169.3zm0 317.3c-81.7 0-148-66.3-148-148s66.3-148 148-148 148 66.3 148 148-66.2 148-148 148z" />
      <path d="M773.1 5884.5h165.5v182.2H773.1z" />
      <circle cx="857.8" cy="5922.6" r="22.2" fill="#FFF" />
      <path d="M870.82 5935.17a13.23 13.23 0 0 1-26 0c0-11.6 11.7-28 11.7-28s14.3 14.4 14.3 28z" />
      <path d="M867.26 5936.47c-1 5.2-6.1 8.6-11.3 7.5a9.5 9.5 0 0 1-7.5-7.5 47.6 47.6 0 0 1 8.1-20.2c-.1 0 10.7 10.4 10.7 20.2z" fill="#FFF" />
      <path d="M861.52 5940.6a3.75 3.75 0 0 1-4.5 4.4c-3 0-3.3-2-3.3-4.4.2-3.1 1.7-5.9 4.2-7.8 1.5 2.5 2.7 5.1 3.6 7.8z" />
      <path fill="#FFF" d="M788.8 6009.5h138.1v44.7H788.8z" />
      <circle cx="132.8" cy="229.9" r="9.6" transform="translate(675.81 5801.69)" />
      <path d="m812.52 6025.86 4.5-3.98 2.32 2.63-4.5 3.97zM885.3 6021.88h31.3v3.5h-31.3zM885.3 6030.13h31.3v3.5h-31.3zM885.3 6038.28h31.3v3.5h-31.3zM838.26 6021.88h31.3v3.5h-31.3zM838.26 6030.13h31.3v3.5h-31.3zM838.26 6038.28h31.3v3.5h-31.3z" />
      <path stroke="#000" strokeWidth=".34" strokeMiterlimit="10" d="M831.8 5806.5h48.3v81.2h-48.3zM795.7 6062.8h8.1v43.8h-8.1zM818.1 6062.8h8.1v43.8h-8.1z" />
      <g><path d="M789.5 6077.8h18.9v3.5h-18.9z" /></g>
      <g><path d="M812.7 6077.8h18.9v3.5h-18.9z" /></g>
    </g>
    <path d="M50.6 120.2c-44.1 82.5-12.9 185.2 69.6 229.3 82.5 44.1 185.2 12.9 229.3-69.6 44.1-82.5 12.9-185.2-69.6-229.3-82.6-44.1-185.2-13-229.3 69.6zm79.6 210.5C63.1 294.8 34 214 63 143.6l260 138.9c-42.3 63.2-125.6 84-192.8 48.2zM337 256.5 77 117.6c45.5-68 137.5-86.2 205.5-40.6 58.5 39.2 81.3 114.3 54.5 179.5z" />
  </svg>
);

export const Flame = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" xmlSpace="preserve">
    <circle cx="200" cy="200" r="200" fill="#FCCF31" />
    <path d="M191.6 95s-64.9 91.1-64.9 155.4c0 32.9 32.3 59.6 72.2 59.6s72.2-26.7 72.2-59.6c0-75.5-79.5-155.4-79.5-155.4zm22.8 203.8c4.6-5 6.6-11.9 5.3-18.6-2.4-12.4-19.7-43.3-19.7-43.3s-23.3 16.8-23.3 43.3c0 6.9.5 13.1 3.1 17.6-19.4-6.3-33.2-21.9-33.2-40.2 0-46.5 44.9-112.4 44.9-112.4s59.6 57.8 59.6 112.4c.1 19.4-15.4 35.8-36.7 41.2z" fill="#FFF" />
    <path d="M199.2 31.4A168.8 168.8 0 1 0 368 200.2c0-93.2-75.6-168.8-168.8-168.8zm0 316.4a147.6 147.6 0 1 1 0-295.2 147.6 147.6 0 0 1 0 295.2z" />
  </svg>
);

export const BackButton = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.46 18.26">
    <path fill="#1d1d1b" d="M18.46 18.26H2.09v-2.49h13.88V4.97H5.36v-2.5h13.1Z" />
    <path fill="#1d1d1b" d="M6.45 7.45 0 3.72 6.45 0Z" />
  </svg>
);

export default { AntiGas, HeatPump, Labels };
