import React from 'react';
import GradientContainer from '../gradient-container';
import PartnerList, { PartnerEntry } from '../partner-list';

import './final-section.scss';

interface FinalSectionProps {
  partners: PartnerEntry[];
  getText: TextFunction.textContentFunction;
}

const CONTACT_US_ENABLED = true;

const FinalSection = ({ getText, partners }: FinalSectionProps) => {
  const supporters = partners.filter(({ partnerType }) => partnerType === 'SUPPORTER');
  return (
    <div className="final-section" id="about">
      <GradientContainer type="secondary">
        <div className="final-section__inner">
          <div className="final-section__about">
            <h2>{getText('About us headline', { stringOnly: true })}</h2>
            {getText('About us')}
          </div>
          <div className="final-section__members-and-supporters">
            <div className="final-section__members">
              <h3>{getText('Our Members', { stringOnly: true })}</h3>
              <PartnerList partners={partners.filter(({ partnerType }) => partnerType === 'MEMBER')} />
            </div>
            { supporters.length > 0 && (
              <div className="final-section__supporters">
                <h3>{getText('Advisory Members', { stringOnly: true })}</h3>
                <PartnerList partners={supporters} />
              </div>
            )}
          </div>
          { CONTACT_US_ENABLED && (
            <div className="final-section__contact">
              <h2>{getText('Contact us headline', { stringOnly: true })}</h2>
              {getText('Contact us')}
            </div>
          )}
        </div>
      </GradientContainer>
    </div>
  );
};

export default FinalSection;
