import React from 'react';

export interface PartnerEntry {
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  url: string;
  // eslint-disable-next-line react/no-unused-prop-types
  partnerType: string;
}

const PartnerList = ({ partners } : { partners: PartnerEntry[] }) => (
  <ul>
    {partners.map(({ name, url }: PartnerEntry) => (
      <li key={name}>
        <a href={url} target="_blank" rel="noreferrer">{name}</a>
      </li>
    ))}
  </ul>
);

export default PartnerList;
