import React, { useRef, useEffect, useContext } from 'react';
import * as d3 from 'd3';
import CountryContext from '../country-context';

import { BackButton } from '../icons';
import './bar-diagram.scss';

interface CountryEntry {
  name: string
  country: string
  value: number | number[]
}

const WIDTH = 600;
const VERTICAL_WIDTH = 1700;
const HEIGHT = 600;

const MARGIN_LEFT = 60;
const MARGIN_TOP = 20;

const valueFromEntry = (entry: number | number[], index: number) => {
  if (Array.isArray(entry)) {
    return entry[index];
  }
  return entry;
};

const getHighestValueFromArray = (array: CountryEntry[]) => {
  const values = array.map((entry) => valueFromEntry(entry.value, 0));
  return Math.max(...values);
};

const formatThousands = (value: d3.NumberValue) => {
  if (value as number >= 1000000) {
    return `${Math.round(value as number / 100000) / 10}m`;
  } if (value as number >= 1000) {
    return `${Math.floor(value as number / 1000)}k`;
  }
  return `${value}`;
};

const createDiagram = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  countryData: CountryEntry[], horizontal : boolean = false,
) => {
  const maxValue = getHighestValueFromArray(countryData);
  if (horizontal) {
    svg.append('g')
      .attr('transform',
        `translate(${20},${20})`);
    const x = d3.scaleLinear()
      .domain([0, maxValue])
      .range([0, WIDTH - (MARGIN_LEFT / 2)]);
    svg.append('g')
      .attr('transform', `translate(40,${HEIGHT - MARGIN_TOP})`)
      .call(d3.axisBottom(x).tickFormat(formatThousands))
      .selectAll('text')
      .style('font-size', '21');

    // Y axis
    const y = d3.scaleBand()
      .range([0, HEIGHT - MARGIN_TOP])
      .domain(countryData.map((d) => d.name))
      .padding(0.2);
    svg.append('g')
      .call(d3.axisLeft(y))
      .attr('transform', 'translate(40,0)')
      .selectAll('text')
      .style('font-size', '21');

    // Bars
    svg.selectAll('m')
      .data(countryData)
      .enter()
      .append('rect')
      .attr('x', x(0))
      .attr('y', ({ name }: CountryEntry) => y(name) || 0)
      .attr('transform', 'translate(42,0)')
      .attr('width', ({ value }: CountryEntry) => x(valueFromEntry(value, 0)))
      .attr('height', y.bandwidth());
  } else {
    svg.append('g')
      .attr('transform',
        `translate(${20},${20})`);
    // X axis
    const x = d3.scaleBand()
      .range([0, VERTICAL_WIDTH])
      .domain(countryData.map(({ name }) => name))
      .padding(0.4);
    svg.append('g')
      .attr('transform', `translate(${MARGIN_LEFT},${HEIGHT})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .attr('class', 'country-label');

    const y = d3.scaleLinear()
      .domain([-15, maxValue])
      .range([HEIGHT - 20, 0]);
    svg.append('g')
      .call(d3.axisLeft(y))
      .attr('transform', `translate(${MARGIN_LEFT},${MARGIN_TOP})`)
      .selectAll('text')
      .style('font-size', '17');

    const g = svg.selectAll('m')
      .data(countryData)
      .enter()
      .append('g')
      .attr('class', 'stacked-bar')
      .attr('text-anchor', 'middle')
      .attr('name', ({ country }: CountryEntry) => country);

    g.append('rect')
      .attr('x', ({ name } : CountryEntry) => x(name) || 0)
      .attr('y', ({ value }: CountryEntry) => y(valueFromEntry(value, 0)) + MARGIN_TOP)
      .attr('transform', `translate(${MARGIN_LEFT},0)`)
      .attr('width', x.bandwidth())
      .attr('height', ({ value }: CountryEntry) => HEIGHT - MARGIN_TOP - y(valueFromEntry(value, 0) - 1));

    g.append('rect')
      .attr('x', ({ name }: CountryEntry) => x(name) || 0)
      .attr('y', ({ value }: CountryEntry) => y(valueFromEntry(value, 1)) + MARGIN_TOP)
      .attr('transform', `translate(${MARGIN_LEFT},0)`)
      .attr('width', x.bandwidth())
      .attr('secondary', 'true')
      .attr('height', ({ value }: CountryEntry) => HEIGHT - MARGIN_TOP - y(valueFromEntry(value, 1)) - 1);

    g.append('text')
      .attr('x', ({ name }: CountryEntry) => x(name) || 0 + x.bandwidth() / 2)
      .attr('y', ({ value }: CountryEntry) => y(valueFromEntry(value, 0)) + MARGIN_TOP)
      .attr('transform', `translate(${MARGIN_LEFT},-5)`)
      .style('font-size', '23')
      .text(({ value }: CountryEntry) => (valueFromEntry(value, 0)));
  }
};

const updateValues = (svg: any, selectedCountry: string) => {
  svg.selectAll('rect')
    .attr(
      'class',
      ({ country }: CountryEntry) => (
        country === selectedCountry ? 'selected' : ''),
    );
};

interface BarDiagramProps {
  horizontal?: boolean
  data: CountryEntry[]
}

const BarDiagram = ({ horizontal, data } : BarDiagramProps) => {
  const ref = useRef(null as unknown as SVGSVGElement);
  const { setCountry, country } = useContext(CountryContext);

  useEffect(() => {
    createDiagram(d3.select(ref.current), data, horizontal);

    const handleClick = (event: MouseEvent) => {
      const target = event.target as SVGRectElement;
      if (target.tagName === 'rect' || target.tagName === 'text') {
        const parent = target.parentNode as Element;
        setCountry(parent.getAttribute('name') || '');
      }
    };

    ref.current.addEventListener('click', handleClick);

    return () => {
      if (ref.current) {
        ref.current.innerHTML = '';
      }
    };
  }, [data]);

  useEffect(() => {
    updateValues(d3.select(ref.current), country);
  }, [country]);

  return (
    <div className="bar-diagram">
      <svg
        className="bar-diagram"
        preserveAspectRatio="xMinYMin meet"
        ref={ref}
        viewBox={`0 0 ${
          (horizontal ? WIDTH : VERTICAL_WIDTH) + MARGIN_LEFT} ${
          horizontal ? HEIGHT + MARGIN_TOP : HEIGHT + MARGIN_TOP * 2}`}
      />
      { country && (
        <button
          className="map__back-button"
          type="button"
          onClick={() => setCountry('')}
        >
          {BackButton}
        </button>
      ) }
    </div>

  );
};

BarDiagram.defaultProps = {
  horizontal: false,
};

export default BarDiagram;
