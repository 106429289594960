import React from 'react';

import './number.scss';

const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const Number = (
  <div className="number">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 600">
      {NUMBERS.map((number) => (
        <React.Fragment key={number}>
          <path d={`M0 ${number * 60}h40v60H0Z`} />
          <text transform={`translate(19 ${4 + (number * 60)})`}>
            <tspan y="40">
              <tspan>{number}</tspan>
            </tspan>
          </text>
        </React.Fragment>
      ))}
    </svg>
  </div>
);

export default Number;
