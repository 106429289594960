import React from 'react';
import { Link } from 'gatsby';

import './thank-you.scss';

const ThankYou = ({ getText }: { getText: TextFunction.textContentFunction }) => {
  const code = getText('Language code', { stringOnly: true });
  const linkPrefix = code === 'en' ? '' : `/${code}`;
  return (
    <div className="thank-you">
      <div className="thank-you__content">
        <h2 className="thank-you__title">{getText('thank-you__title')}</h2>
        <div className="thank-you__text">
          {getText('Why are we better without boilers?')}
        </div>
        <div className="thank-you__cta">
          <Link to={`${linkPrefix}/resources`} className="thank-you__link">
            {getText('thank-you__link')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
