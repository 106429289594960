import React, { useContext, useMemo } from 'react';

import { AntiOil, AntiGas, BoilerSubsidies } from '../icons';
import BoxWithLabel from '../box-with-label';
import CountryContext from '../country-context';
import DiagramWrapper from '../diagram-wrapper';
import Map, { TabEntry } from '../map';
import TabContext from '../tabbed-context';
import './bans-map.scss';

const key = {
  BAN_OIL_NEW_BUILDINGS: '1. Ban on new oil boilers in new buildings',
  BAN_OIL_NEW_BUILDINGS_UNDER: '1. Text under date',
  BAN_OIL_NEW_BUILDINGS_OVER: '1. Text over date',
  BAN_OIL_ALL_BUILDINGS: '2. Ban on new oil boilers in all buildings',
  BAN_OIL_ALL_BUILDINGS_UNDER: '2. Text under date',
  BAN_OIL_ALL_BUILDINGS_OVER: '2. Text over date',
  BAN_GAS_NEW_BUILDINGS: '3. Ban on new gas boilers in new buildings',
  BAN_GAS_NEW_BUILDINGS_UNDER: '3. Text under date',
  BAN_GAS_NEW_BUILDINGS_OVER: '3. Text over date',
  BAN_GAS_ALL_BUILDINGS: '4. Ban on new gas boilers in all buildings ',
  BAN_GAS_ALL_BUILDINGS_UNDER: '4. Text under date',
  BAN_GAS_ALL_BUILDINGS_OVER: '4. Text over date',
  SUBSIDY: 'Subsidy for new boilers',
};

const processEntry = (entry: any) => {
  const newEntry: { [key: string]: any } = {};
  newEntry[key.BAN_OIL_NEW_BUILDINGS] = entry.countryId.oilNew || '';
  newEntry[key.BAN_OIL_NEW_BUILDINGS_UNDER] = entry.gasNewBelow;
  newEntry[key.BAN_OIL_NEW_BUILDINGS_OVER] = entry.gasNewAbove;
  newEntry[key.BAN_OIL_ALL_BUILDINGS] = entry.countryId.oilAll || '';
  newEntry[key.BAN_OIL_ALL_BUILDINGS_UNDER] = entry.oilAllBelow;
  newEntry[key.BAN_OIL_ALL_BUILDINGS_OVER] = entry.oilAllAbove;
  newEntry[key.BAN_GAS_NEW_BUILDINGS] = entry.countryId.gasNew || '';
  newEntry[key.BAN_GAS_NEW_BUILDINGS_UNDER] = entry.gasNewBelow;
  newEntry[key.BAN_GAS_NEW_BUILDINGS_OVER] = entry.gasNewAbove;
  newEntry[key.BAN_GAS_ALL_BUILDINGS] = entry.countryId.gasAll || '';
  newEntry[key.BAN_GAS_ALL_BUILDINGS_UNDER] = entry.gasAllBelow;
  newEntry[key.BAN_GAS_ALL_BUILDINGS_OVER] = entry.gasAllAbove;
  newEntry[key.SUBSIDY] = entry.countryId.isSubsidized === 'TRUE' ? 'Y' : 'N';
  newEntry.Country = entry.countryId.country;

  return newEntry;
};

const BANS_MAP_TABS: TabEntry[] = [
  {
    name: 'Bans on oil',
    description: 'Bans on new oil boilers in buildings',
    icon: AntiOil,
    color: 'magenta',
  },
  {
    name: 'Bans on gas',
    description: 'Bans on new gas boilers in buildings',
    icon: AntiGas,
    color: 'yellow',
  },
  {
    name: 'Boiler subsidies',
    description: 'Subsidies available for new fossil fuel boilers',
    icon: BoilerSubsidies,
    color: 'red',
  },
];

const processCountryData = (dictionary: { [key: string]: any }, ourKey : string) => {
  const newData = {} as {[key: string]: number};

  Object.keys(dictionary).forEach((objKey: string) => {
    if (dictionary[objKey][ourKey] && dictionary[objKey][ourKey] !== 'N') {
      newData[objKey] = 1;
    }
  });
  return newData;
};

const COLORS = ['#E81CFF', '#FCCF31', '#F55555'];

const BansMap = (
  { getText, data: mapData }: { getText: TextFunction.textContentFunction, data: any[]},
) => {
  const { country } = useContext(CountryContext);
  const { activeTab, setActiveTab } = useContext(TabContext);

  const translated = (stringKey: string) => getText(stringKey, { stringOnly: true });

  const translatedTabs = BANS_MAP_TABS.map((tab) => ({
    ...tab,
    name: translated(tab.name),
    description: translated(tab.description),
  }));

  const [countryData, oilData, gasData, subData] = useMemo(() => {
    const dataObject: { [key: string]: any } = {};

    mapData.forEach((mapDataEntry) => {
      const processedEntry = processEntry(mapDataEntry);
      dataObject[mapDataEntry.countryId.country] = processedEntry;
    });

    return [
      dataObject,
      processCountryData(dataObject, key.BAN_OIL_NEW_BUILDINGS),
      processCountryData(dataObject, key.BAN_GAS_NEW_BUILDINGS),
      processCountryData(dataObject, key.SUBSIDY),
    ];
  }, [mapData]);

  const keyForContryHasData = (selectedCountry: string, dataKey: string) => {
    const data = countryData as any;
    return selectedCountry in countryData && data[selectedCountry][dataKey];
  };

  const getCountryValueForKey = (
    selectedCountry: string, dataKey: string, blank : boolean = false,
  ) => {
    const data = countryData as any;
    if (keyForContryHasData(selectedCountry, dataKey)) {
      return data[selectedCountry][dataKey];
    }
    return blank ? '' : translated('No date set');
  };

  const subsidiesAvailable = () => {
    const value = getCountryValueForKey(country, key.SUBSIDY).toLowerCase();
    if (value === 'y') {
      return translated('Subsidies available');
    }
    return translated('Subsidies unavailable');
  };

  const underAll = (
    <div className="bans-map-country-info__under">
      {getCountryValueForKey(
        country,
        activeTab === 0 ? key.BAN_OIL_ALL_BUILDINGS_UNDER : key.BAN_GAS_ALL_BUILDINGS_UNDER,
      )}
    </div>
  );

  const underNew = (
    <div className="bans-map-country-info__under">
      {getCountryValueForKey(
        country,
        activeTab === 0 ? key.BAN_OIL_NEW_BUILDINGS_UNDER : key.BAN_GAS_NEW_BUILDINGS_UNDER,
      )}
    </div>
  );

  const getCountryInfo = () => (
    <div className="bans-map-country-info">
      <div className={`bans-map-country-info__details${activeTab === 2 ? ' bans-map-country-info__details--subsidies' : ''}`}>
        {BANS_MAP_TABS[activeTab].icon}
        { activeTab === 2 ? (
          <div className="bans-map-country-info__subsidies">
            {subsidiesAvailable()}
          </div>
        ) : (
          <>
            <div className="bans-map-country-info__all-buildings">
              <span className="bans-map-country-info__label">
                {getCountryValueForKey(country,
                  activeTab === 0
                    ? key.BAN_OIL_ALL_BUILDINGS_OVER : key.BAN_GAS_ALL_BUILDINGS_OVER, true)}
              </span>
              <span className="bans-map-country-info__number">
                {getCountryValueForKey(
                  country, activeTab === 0 ? key.BAN_OIL_ALL_BUILDINGS : key.BAN_GAS_ALL_BUILDINGS,
                )}
              </span>
              {keyForContryHasData(country,
                activeTab === 0
                  ? key.BAN_OIL_ALL_BUILDINGS_UNDER : key.BAN_GAS_ALL_BUILDINGS_UNDER) && underAll}
            </div>
            <div className="bans-map-country-info__new-build">
              <span className="bans-map-country-info__label">
                {getCountryValueForKey(country,
                  activeTab === 0
                    ? key.BAN_OIL_NEW_BUILDINGS_OVER : key.BAN_GAS_NEW_BUILDINGS_OVER, true)}
              </span>
              <span className="bans-map-country-info__number">
                {getCountryValueForKey(country,
                  activeTab === 0 ? key.BAN_OIL_NEW_BUILDINGS : key.BAN_GAS_NEW_BUILDINGS)}
              </span>
              {keyForContryHasData(country,
                activeTab === 0
                  ? key.BAN_OIL_NEW_BUILDINGS_UNDER : key.BAN_GAS_NEW_BUILDINGS_UNDER) && underNew}
            </div>
          </>
        )}
      </div>
      <div className="bans-map-country-info__other-options">
        {
          [
            (
              <button
                className="bans-map-country-info__other-option bans-map-country-info__other-option--magenta"
                onClick={() => setActiveTab(0)}
                type="button"
                key="oil"
              >
                {BANS_MAP_TABS[0].icon}
                {' '}
                {getCountryValueForKey(country, key.BAN_OIL_ALL_BUILDINGS) !== translated('No date set') ? `${translated('By [YEAR]')} ` : '' }
                { getCountryValueForKey(country, key.BAN_OIL_ALL_BUILDINGS) }
              </button>
            ),
            (
              <button
                className="bans-map-country-info__other-option bans-map-country-info__other-option--yellow"
                onClick={() => setActiveTab(1)}
                type="button"
                key="gas"
              >
                {BANS_MAP_TABS[1].icon}
                {' '}
                {getCountryValueForKey(country, key.BAN_GAS_ALL_BUILDINGS) !== translated('No date set') ? `${translated('By [YEAR]')} ` : '' }
                { getCountryValueForKey(country, key.BAN_GAS_ALL_BUILDINGS) }
              </button>
            ),
            (
              <button
                className="bans-map-country-info__other-option bans-map-country-info__other-option--red"
                onClick={() => setActiveTab(2)}
                type="button"
                key="subsidies"
              >
                {BANS_MAP_TABS[2].icon}
                {subsidiesAvailable()}
              </button>
            ),
          ].filter((_, i) => i !== activeTab)
        }
      </div>
    </div>
  );

  return (
    <div className="bans-map">
      <DiagramWrapper
        title={translated('Which countries are already leaving boilers in the past?')}
        description={getText('Is Europe on track to phase out boilers? Intro')}
      >
        <Map
          countryData={[oilData, gasData, subData][activeTab]}
          tabs={translatedTabs}
          highlightColor={COLORS[activeTab]}
          startColor={COLORS[activeTab]}
          endColor={COLORS[activeTab]}
          overviewLabel={getText('Overview', { stringOnly: true })}
        />
        <div>
          <BoxWithLabel
            label={country || getText('Across Europe', { stringOnly: true })}
            icon={country ? null : BANS_MAP_TABS[activeTab].icon}
            color={BANS_MAP_TABS[activeTab].color}
          >
            {country ? getCountryInfo() : (
              <>
                {getText('Is Europe on track to phase out boilers?  Text box 1st indicator at EU level')}
              </>
            )}
          </BoxWithLabel>
        </div>
      </DiagramWrapper>
    </div>
  );
};

export default BansMap;
