import React from 'react';

import BarDiagram from '../bar-diagram';

import './versus-diagrams.scss';

interface VersusDiagramsProps {
  getText: TextFunction.textContentFunction,
  salesData: any[],
  installationData: any[],
  type: 'boiler' | 'heat-pump'
}

const VersusDiagrams = (
  {
    salesData, installationData, type, getText,
  }: VersusDiagramsProps,
) => {
  const prefix = type === 'boiler' ? 'Boilers' : 'Heat pumps';
  return (
    <div className="versus-diagrams">
      <div className="versus-diagrams__diagram">
        <h3 className="versus-diagrams__title">
          {getText(`${prefix} installed`, { stringOnly: true })}
          {' '}
          (2020)
        </h3>
        <BarDiagram horizontal data={installationData} />
      </div>
      <div className="versus-diagrams__diagram">
        <h3 className="versus-diagrams__title">
          {getText(`${prefix} sold`, { stringOnly: true })}
          {' '}
          (2020)
        </h3>
        <BarDiagram horizontal data={salesData} />
      </div>
    </div>
  );
};

export default VersusDiagrams;
