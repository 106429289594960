import React, { useMemo, useContext } from 'react';

import './versus-map.scss';

// eslint-disable-next-line import/no-unresolved
import { parse } from 'csv-parse/browser/esm/sync';
import VersusDiagrams from './versus-diagrams';

import Map, { TabEntry } from '../map';
import { HeatPump, Boiler } from '../icons';
import DiagramWrapper from '../diagram-wrapper';
import BoxWithLabel from '../box-with-label';

import CountryContext from '../country-context';
import TabContext from '../tabbed-context';
import IconWithStatList from '../icon-with-stat-list';
import { contryNameToCode, sortByKey } from '../../utils/country-data';

const VERSUS_TABS: TabEntry[] = [
  {
    name: 'VersusMap subject 1',
    description: 'Heat pumps installed per 1000 inhabitants',
    icon: HeatPump,
    color: 'green',
  },
  {
    name: 'VersusMap subject 2',
    description: 'Boilers installed per 1000 inhabitants',
    icon: Boiler,
    color: 'red',
  },
];

const START_COLORS = ['#EEFEEF', '#FEEEEE'];
const END_COLORS = ['#55F25B', '#F55555'];

const DATA_FILE = 'Data-Technologies.csv';

const key = {
  BOILERS_INSTALLED: 'Boilers installed',
  BOILERS_INSTALLED_PI: 'Boilers installed per 1000 inhabitant',
  BOILERS_SOLD: 'Boilers sold',
  HEAT_PUMPS_INSTALLED: 'Heat pumps installed',
  HEAT_PUMPS_INSTALLED_PI: 'Heat pumps installed per 1000 inhabitant',
  HEAT_PUMPS_SOLD: 'Heat pumps sold',
  HEAT_PUMPS_INCREASE: 'Heat pumps sales increase (2020-2021)',
};

const parseValue = (value: string) => {
  const parsed = parseInt(value.replaceAll(',', ''), 10);
  return Number.isNaN(parsed) ? 0 : parsed;
};

const VersusMap = ({ getText }: { getText: TextFunction.textContentFunction }) => {
  const { country } = useContext(CountryContext);
  const { activeTab, setActiveTab } = useContext(TabContext);

  const translatedTabs = useMemo(() => VERSUS_TABS.map((tab) => ({
    ...tab,
    name: getText(tab.name, { stringOnly: true }),
    description: getText(tab.description, { stringOnly: true }),
  })), [getText]);

  const [
    countryData,
    heatPumpData,
    boilerData,
    hpSalesData,
    bSalesData,
    hpInstallationsData,
    bInstallationsData,
  ] = useMemo(() => {
    const data = parse(getText(DATA_FILE, { stringOnly: true }) as string, {
      columns: true,
      skip_empty_lines: true,
    });

    const allData = {} as any;
    const hpData = {} as any;
    const bData = {} as any;
    const hpSales = [] as any[];
    const bSales = [] as any[];
    const hpInstallations = [] as any[];
    const bInstallations = [] as any[];

    data.forEach((entry: any) => {
      if (entry.Country !== 'EU') {
        allData[entry.Country] = entry;
        hpData[entry.Country] = parseValue(entry[key.HEAT_PUMPS_INSTALLED_PI]);
        hpSales.push({
          name: contryNameToCode(entry.Country),
          value: parseValue(entry[key.HEAT_PUMPS_SOLD]),
        });
        hpInstallations.push({
          name: contryNameToCode(entry.Country),
          value: parseValue(entry[key.HEAT_PUMPS_INSTALLED]),
        });
        bData[entry.Country] = parseValue(entry[key.BOILERS_INSTALLED_PI]);
        bSales.push({
          name: contryNameToCode(entry.Country),
          value: parseValue(entry[key.BOILERS_SOLD]),
        });
        bInstallations.push({
          name: contryNameToCode(entry.Country),
          value: parseValue(entry[key.BOILERS_INSTALLED]),
        });
      }
    });

    return [
      allData,
      hpData,
      bData,
      hpSales.sort(sortByKey('value')).slice(0, 10),
      bSales.sort(sortByKey('value')).slice(0, 10),
      hpInstallations.sort(sortByKey('value')).slice(0, 10),
      bInstallations.sort(sortByKey('value')).slice(0, 10),
    ];
  }, [getText]);

  const getData = (dataKey: string) => {
    if (country in countryData) {
      return countryData[country][dataKey] || (
        <span className="no_data">{getText('NDA')}</span>
      );
    }

    return (
      <span className="no_data">{getText('NDA')}</span>
    );
  };
  return (
    <div className="versus-map">
      <DiagramWrapper
        title={getText('Who is leading the race on heat pumps? Title', { stringOnly: true })}
        description={getText('Who is leading the race on heat pumps?')}
      >
        <Map
          countryData={[heatPumpData, boilerData][activeTab]}
          tabs={translatedTabs}
          highlightColor={undefined}
          startColor={START_COLORS[activeTab]}
          endColor={END_COLORS[activeTab]}
          showBar
          overviewLabel={getText('Overview', { stringOnly: true })}
        />
        <div>
          <BoxWithLabel
            label={getText(country, { stringOnly: true }) || getText('Across Europe', { stringOnly: true })}
            icon={country ? null : VERSUS_TABS[activeTab].icon}
            color={VERSUS_TABS[activeTab].color}
          >
            {country ? (
              <>
                {activeTab === 1 ? (
                  <IconWithStatList
                    icon={VERSUS_TABS[1].icon}
                    color={VERSUS_TABS[1].color}
                    stats={[
                      {
                        value: getData(key.BOILERS_INSTALLED),
                        label: getText(key.BOILERS_INSTALLED, { stringOnly: true }),
                      },
                      {
                        value: getData(key.BOILERS_INSTALLED_PI),
                        label: getText(key.BOILERS_INSTALLED_PI, { stringOnly: true }),
                      },
                      {
                        value: getData(key.BOILERS_SOLD),
                        label: getText(key.BOILERS_SOLD, { stringOnly: true }),
                      },
                    ]}
                  />
                ) : (
                  <IconWithStatList
                    icon={VERSUS_TABS[0].icon}
                    color={VERSUS_TABS[0].color}
                    stats={[
                      {
                        value: getData(key.HEAT_PUMPS_INSTALLED),
                        label: getText(key.HEAT_PUMPS_INSTALLED, { stringOnly: true }),
                      },
                      {
                        value: getData(key.HEAT_PUMPS_INSTALLED_PI),
                        label: getText(key.HEAT_PUMPS_INSTALLED_PI, { stringOnly: true }),
                      },
                      {
                        value: getData(key.HEAT_PUMPS_SOLD),
                        label: getText(key.HEAT_PUMPS_SOLD, { stringOnly: true }),
                      },
                      {
                        value: getData(key.HEAT_PUMPS_INCREASE),
                        label: getText(key.HEAT_PUMPS_INCREASE, { stringOnly: true }),
                      },
                    ]}
                  />
                )}

                <button
                  className={`versus-map__secondary-metric versus-map__secondary-metric--color-${(['red', 'green'])[activeTab]}`}
                  onClick={() => setActiveTab(activeTab === 0 ? 1 : 0)}
                  type="button"
                >
                  {VERSUS_TABS[(activeTab + 1) % 2].icon}
                  <div>
                    {[getData(key.BOILERS_INSTALLED),
                      getData(key.HEAT_PUMPS_INSTALLED)][activeTab]}

                  </div>
                  <div>
                    {[getData(key.BOILERS_INSTALLED_PI),
                      getData(key.HEAT_PUMPS_INSTALLED_PI)][activeTab]}
                  </div>
                </button>
              </>
            ) : (
              <div>
                {activeTab === 0 ? (
                  <>
                    <h3>{getText('VersusMap Title 1')}</h3>
                    {getText('Heat pump view, in box "across the EU", top of the charts')}
                    <VersusDiagrams
                      getText={getText}
                      salesData={hpSalesData}
                      installationData={hpInstallationsData}
                      type="heat-pump"
                    />
                    {getText('Heat pump view, in box "across the EU", bottom of the charts')}
                  </>
                ) : (
                  <>
                    <h3>{getText('VersusMap Title 2')}</h3>
                    {getText('Boilers view, in box "across the EU", top of the charts')}
                    <VersusDiagrams
                      getText={getText}
                      salesData={bSalesData}
                      installationData={bInstallationsData}
                      type="boiler"
                    />
                    {getText('Boilers view, in box "across the EU", bottom of the charts')}
                  </>
                )}
              </div>
            )}

            <div className="versus-map__source">
              {getText('Across the EU charts source', { stringOnly: true })}
            </div>
            <div className="versus-map__notes">
              {getText('Across the EU charts notes', { stringOnly: true })}
            </div>
          </BoxWithLabel>
        </div>
      </DiagramWrapper>
    </div>
  );
};

export default VersusMap;
