import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import {
  AntiGas, HeatPump, BoilerSubsidies, AntiOil, Boiler,
} from '../icons';
import './exploring-the-data.scss';

const ExploringTheData = ({ getText }: { getText: TextFunction.textContentFunction}) => {
  const [ref, isRevealed] = useIsRevealed({ threshold: 0.4 });
  return (
    <div
      className={`exploring-the-data${isRevealed ? ' is-revealed' : ''}`}
      ref={ref}
      id="explore"
    >
      <div className="exploring-the-data__inner">
        <h2 className="exploring-the-data__title">
          {getText('Menu Explore The Data', { stringOnly: true })}
        </h2>
        <div className="exploring-the-data__icons">
          {AntiGas}
          {HeatPump}
          {BoilerSubsidies}
          {AntiOil}
          {Boiler}
        </div>
        <div>
          {getText('Explore the data')}
        </div>
      </div>
    </div>
  );
};

export default ExploringTheData;
