import React, { createContext, useState, ReactNode } from 'react';

const TabbedContext = createContext({
  activeTab: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActiveTab: (tab: number) => {},
});

export const TabProvider = ({ children }: { children: ReactNode }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabbedContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabbedContext.Provider>
  );
};

export default TabbedContext;
