import React from 'react';
import colorBetween from 'color-between';

import './scale-bar.scss';

interface ScaleBarProps {
  segmentCount: number
  startColor: string
  endColor: string
  startNumber: number
  endNumber: number
}

// TODO: Find solution for localized numbers
const formatThousands = (value: number) => {
  if (value >= 1000000) {
    return `${Math.round(value / 100000) / 10}m`;
  } if (value >= 1000) {
    return `${Math.floor(value / 1000)}k`;
  }
  return `${Math.round(value / 10) * 10}`;
};

const getValueBetween = (start: number, end: number, index: number, count: number) => {
  const step = (end - start) / count;
  return formatThousands(start + (step * index));
};

const ScaleBar = ({
  segmentCount, startColor, endColor, startNumber, endNumber,
}: ScaleBarProps) => (

  <div className="scale-bar-container">
    <div className="scale-bar-container__inner">
      <svg viewBox={`0 0 1 ${segmentCount}`} className="scale-bar">
        <g className="scale-bar__bar">
          { (new Array(segmentCount)).fill(0).map((_, i) => (
            <rect
              width="1"
              height="1"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              y={i}
              stroke="#000"
              strokeWidth={0.05}
              fill={colorBetween(startColor, endColor, 1 - i / segmentCount, 'hex')}
            />
          )) }
        </g>
      </svg>
      <div className="scale-bar-container__numbers">
        {(new Array(segmentCount)).fill(0).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>{getValueBetween(endNumber, startNumber, i, segmentCount)}</div>
        ))}
      </div>
    </div>
  </div>
);

export default ScaleBar;
