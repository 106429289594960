import React from 'react';
import useIsRevealed from '../../utils/use-is-revealed';

import Number from './number';
import './boiler-animation.scss';

const BoilerAnimation = ({ getText }: { getText: TextFunction.textContentFunction }) => {
  const [ref, isRevealed] = useIsRevealed({ threshold: 0.5 });
  const [textTop, textMiddle] = getText(
    'There are currently [NUMBER] boilers in the EU', { stringOnly: true },
  ).split('[NUMBER]');
  const isEnglish = getText('Language', { stringOnly: true }) === 'English';
  return (
    <div className="boiler-animation-container">
      <div
        className={`boiler-animation${isRevealed ? ' ready' : ''}`}
        ref={ref}
      >
        <div className="boiler-animation__liquid" />
        <div className={`boiler-animation__numbers${
          isRevealed ? ' ready' : ''}`}
        >
          {Number}
          {Number}
          {Number}
          {Number}
          {Number}
          {Number}
          {Number}
          {Number}
          {Number}
          {Number}
        </div>
        {isEnglish ? (
          <div className={`boiler-animation__numbers-word${
            isRevealed ? ' ready' : ''}`}
          >
            {('90 million').split('').map((letter, i) => (
            // eslint-disable-next-line react/no-array-index-key
              <div className="number number--char" key={letter + i}>
                {letter}
              </div>
            ))}
          </div>
        ) : ''}
        <div className="boiler-animation__text boiler-animation__text--top">
          {textTop}
        </div>
        <div className="boiler-animation__text boiler-animation__text--middle">
          {textMiddle}
        </div>
        <div className="boiler-animation__text boiler-animation__text--bottom">
          {isEnglish ? (
            <>
              A new boiler is installed every
              {' '}
              <span>8 seconds</span>
            </>
          ) : getText('A new boiler is installed every 8 seconds', { stringOnly: true })}
        </div>
        <div className="boiler-animation__flame">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.8 110">
            <path
              fill="#fff"
              d="M114 57a57 57 0 0 1-37 53 34 34 0 0 0 13-21c0-29-30-71-30-71S24
              54 24 89a34 34 0 0 0 12 21 57 57 0 1 1 78-53Z"
            />
            <path
              fill="#fff"
              d="M33 90c0-25 27-52 27-52a123 123 0 0 1 21 52 25 25 0 0 1-15
              18l1-8a27 27 0 0 0-11-20 115 115 0 0 0-9 20 10 10 0 0 0 2 8 25 25
              0 0 1-16-18Z"
            />
          </svg>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 67.1 67.1"
          className="boiler-animation__clock"
        >
          <g>
            <g fill="none" stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth=".7">
              <path d="M37 .2v2.2" />
              <path d="M40.6.7 40 3" />
              <path d="m44 1.6-.7 2.1" />
              <path d="m47.2 2.9-.9 2" />
              <path d="m50.3 4.5-1 1.9" />
              <path d="M53.3 6.4 52 8.2" />
              <path d="m56 8.6-1.5 1.7" />
              <path d="m58.5 11-1.7 1.5" />
            </g>
            <path
              fill="none"
              stroke="#1d1d1b"
              strokeLinejoin="round"
              strokeWidth="2.8"
              d="M33.5 33.5V14"
            />
            <g
              fill="none"
              stroke="#1d1d1b"
              strokeMiterlimit="10"
              strokeWidth="1.4"
            >
              <path d="M33.5 0v5.1" />
              <path d="m16.8 4.5 2.5 4.4" />
              <path d="m4.5 16.8 4.4 2.5" />
              <path d="M0 33.5h5.1" />
              <path d="m4.5 50.3 4.4-2.5" />
              <path d="m16.8 62.6 2.5-4.4" />
              <path d="M33.5 67.1V62" />
              <path d="m50.3 62.6-2.5-4.4" />
              <path d="m62.6 50.3-4.4-2.5" />
              <path d="M67.1 33.5H62" />
              <path d="m62.6 16.8-4.4 2.5" />
              <path d="m50.3 4.5-2.5 4.4" />
            </g>
            <g transform="translate(32 7)">
              <circle
                cx="1.5"
                cy="1.5"
                r="1.5"
                fill="#1d1d1b"
                transform="translate(0 25)"
              />
              <path
                fill="none"
                stroke="#1d1d1b"
                strokeLinejoin="round"
                strokeWidth="2.8"
                d="M7.8 0 1.5 26.5"
                className="boiler-animation__clock-minute-hand"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default BoilerAnimation;
