import React from 'react';

const WITHOUT_BOILERS = (
  <g>
    <path
      d="M1353.9 822.8a46 46 0 0 1-15-2.4 59.4 59.4 0 0 1-11-4.8l-8.2 22.6c3.3 2
      8.1 3.8 14.3 5.4a87.4 87.4 0 0 0 21.7 2.4c6.5 0 12.5-.9 17.8-2.6a33.7 33.7
      0 0 0 22.5-20.6c2-5.2 3.1-11.4 3.1-18.4s-1.3-13.2-3.8-18.3a50.1 50.1 0 0
      0-9.5-13.4c-3.8-3.8-8-7.1-12.4-10a359 359 0 0
      1-12.4-8.3c-3.8-2.7-7-5.4-9.5-8.3a14.5 14.5 0 0 1-3.8-10c0-4 1.4-7.2
      4.2-9.3 2.8-2.1 6.8-3.2 12-3.2a50.6 50.6 0 0 1 24.6
      6.4l7.8-22.2c-3.9-2.3-8.7-4-14.6-5.4-5.9-1.3-12.2-2-19-2-5.6 0-10.9.7-15.8
      2-5 1.5-9.2 3.7-12.8 6.7a31 31 0 0 0-8.5 11.6 43 43 0 0 0-3.1 17.3 41.4
      41.4 0 0 0 13.3 31.8c3.8 3.7 8 7 12.4 9.8 4.5 2.8 8.6 5.5 12.4 8.2 3.8 2.7
      7 5.5 9.5 8.5s3.8 6.6 3.8 10.9c0 10.4-6.7 15.6-20 15.6Z"
    />
    <path
      d="M1208.3 843.2h25.2v-57.8l10.8 2.6 24.4 55.2h29l-25.8-53.6-8-6.4a32.7
      32.7 0 0 0 17.3-14.7 54 54 0 0 0 6.3-27.7 43 43 0 0
      0-3.9-19.5c-2.6-5.1-6-9.2-10.2-12.2s-9-5-14.5-6.2c-5.5-1.2-11-1.7-16.8-1.7a196.5
      196.5 0 0 0-33.8 3.4v138.6Zm25.2-117.8c1.2-.4 2.8-.7 4.8-.8l6.2-.2c5.7 0
      10 1.9 12.6 5.7a25 25 0 0 1 4 14.7c0 8-1.6 13.9-4.8 17.7-3.2 3.8-8
      5.7-14.2 5.7h-8.6v-42.8Z"
    />
    <path
      d="M1106.3
      843.2h70.8V820h-45.6v-36h40.6v-23.2h-40.6v-34.4h44.8v-23.2h-70v140z"
    />
    <path
      d="M1079.9 820h-49.2V703.2h-25.2v140h74.4V820z"
    />
    <path
      d="M944.1 703.2h25.2v140h-25.2z"
    />
    <path
      d="M814.9 803.3a69 69 0 0 0 8.8 23c4 6.3 9.1 11.2 15.3 14.6a46.2 46.2 0 0
      0 22.5 5c16.4 0 28.8-6.1 37.1-18.5s12.5-30.5
      12.5-54.2c0-11-1-21.1-2.9-30.1-2-9-4.9-16.7-8.9-23a41.4 41.4 0 0
      0-37.8-19.7c-16.7 0-29 6.2-37.2 18.6-8.1 12.4-12.2 30.4-12.2 54.2 0 11 1
      21 2.8 30Zm46.6-79.7c4.4 0 8 1.3 11 3.9 3 2.6 5.3 6.1 7.1 10.6 1.8 4.4 3.1
      9.7 3.9 15.8.8 6 1.2 12.5 1.2 19.3 0 17.3-2 30-5.8 37.8-3.9 7.8-9.7
      11.8-17.4 11.8-4.3 0-7.8-1.3-10.7-3.8-2.9-2.6-5.2-6-7.1-10.4a60.4 60.4 0 0
      1-4-15.7 151 151 0 0 1-1.2-19.7c0-33 7.7-49.6 23-49.6Z"
    />
    <path
      d="M777.7
      718.3c-2.4-4.5-5.6-8-9.7-10.5-4-2.5-8.8-4.3-14.2-5.2-5.4-1-11-1.4-16.9-1.4a208.9
      208.9 0 0 0-35.6 3v138c3 .8 6.4 1.4 10 1.8l10.5.9 9.5.3h7.2c6.3 0 12.2-.8
      17.8-2.4 5.6-1.6 10.5-4 14.7-7.4 4.2-3.4 7.5-7.7 10-13a43.7 43.7 0 0 0
      3.7-18.6c0-5.4-.6-10-1.9-14-1.3-3.9-3-7.2-5.1-10a24.8 24.8 0 0 0-7.5-6.4
      37 37 0 0 0-9.1-3.6v-1.2a26.9 26.9 0 0 0 15.6-13.1c1.6-3 2.8-6.2
      3.5-9.6a49 49 0 0 0 1.1-10.3c0-7-1.2-12.9-3.6-17.3ZM732
      823c-2.6-.2-4.4-.5-5.5-1v-39.7h10.2c2.8 0 5.5.2 8.1.6a14.6 14.6 0 0 1 11.7
      8.7c1.2 2.6 1.8 6 1.8 10.5 0 7-1.8 12.2-5.4 15.8a18.7 18.7 0 0 1-13.8
      5.4l-7.1-.3Zm-5.5-60.5v-38.8a57.8 57.8 0 0 1 18.7 0c2.2.7 4.1 1.7 5.7 3
      1.6 1.5 2.9 3.4 3.8 5.8 1 2.4 1.4 5.3 1.4 8.8 0 5.3-1.4 9.7-4.1 13-2.7
      3.3-6 5.7-9.9 7.2a42 42 0 0 1-9.8 1h-5.8Z"
    />
    <path
      d="M1447.9 512.2h-91.4v23.2h33.2v116.8h25.2V535.4h33v-23.2z"
    />
    <path
      d="M1306.7 602c0 10.4-1.4 17.9-4.2 22.5-2.8 4.6-7.5 6.9-14
      6.9s-11.7-2.2-14.6-6.5c-3-4.3-4.4-12-4.4-23v-89.7h-25.2v94.2c0 16.3 3.6
      28.4 10.7 36.3 7.1 8 18.4 11.9 33.7 11.9 13 0 23.3-4 30.7-12.2 7.4-8.1
      11.1-21.3 11.1-39.4v-90.8h-23.8V602Z"
    />
    <path
      d="M1117.9 612.3a69 69 0 0 0 8.8 23c4 6.3 9.1 11.2 15.3 14.6a46.2 46.2 0 0
      0 22.5 5c16.4 0 28.8-6.1 37.1-18.5s12.5-30.5
      12.5-54.2c0-11-1-21.1-2.9-30.1-2-9-4.9-16.7-8.9-23a41.4 41.4 0 0
      0-37.8-19.7c-16.7 0-29 6.2-37.2 18.6-8.1 12.4-12.2 30.4-12.2 54.2 0 11 1
      21 2.8 30Zm46.6-79.7c4.4 0 8 1.3 11 3.9 3 2.6 5.3 6.1 7.1 10.6 1.8 4.4 3.1
      9.7 3.9 15.8.8 6 1.2 12.5 1.2 19.3 0 17.3-2 30-5.8 37.8-3.9 7.8-9.7
      11.8-17.4 11.8-4.3 0-7.8-1.3-10.7-3.8-2.9-2.6-5.2-6-7.1-10.4a60.4 60.4 0 0
      1-4-15.7 151 151 0 0 1-1.2-19.7c0-33 7.7-49.6 23-49.6Z"
    />
    <path
      d="M1059.1
      652.2h25.2v-140h-25.2v57.6h-38.2v-57.6h-25.2v140h25.2V593h38.2v59.2z"
    />
    <path
      d="M969.1 512.2h-91.4v23.2h33.2v116.8h25.2V535.4h33v-23.2z"
    />
    <path
      d="M823.7 512.2h25.2v140h-25.2z"
    />
    <path
      d="M680.5 512.2h-27.8l31.2 141h18.6l19.6-77 2.4-22h1l2.6 21.8 19.6
      77.2h17.8l31.4-141h-25.6l-12.4 76.6-2.2
      25.6h-.8l-2.2-25.2-18.2-77h-17.6l-18.8 76.6-2.2 25.6h-.8l-2-25.2-13.6-77z"
    />
  </g>
);

export const Local = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2100 1152">
    {WITHOUT_BOILERS}
    <g id="Local_2_Outlines">
      <path
        id="l-2"
        d="M1323.8 430.8h-49.2V314h-25.2v140h74.4v-23.2z"
      />
      <path
        id="a"
        d="M1191.4 454h26.4L1177 313h-19.8l-40.6 141h25l8.2-32h33l8.6
        32Zm-28.4-84.8 3.4-22.2h.8l3.2 22 8 32.6h-23.2l7.8-32.4Z"
      />
      <path
        id="c"
        d="M1088.4 427.2a47.9 47.9 0 0 1-9 4.5 33.7 33.7 0 0 1-12 1.9c-5.3
        0-9.9-1.2-13.7-3.6-3.8-2.4-7-5.8-9.4-10.2a51 51 0 0
        1-5.4-15.7c-1.1-6-1.7-12.8-1.7-20.1 0-17 2.5-29.6 7.4-37.6 5-8 12-12
        21.2-12 4.7 0 8.7.4 12.1 1.1 3.4.7 6.4 1.8 8.9 3.1l5-22.2a80.5 80.5 0 0
        0-29-5.2c-6.5 0-12.7 1.1-19 3.4a40.5 40.5 0 0 0-16.7 11.7c-4.9 5.5-8.8
        13-11.8 22.3s-4.5 21-4.5 35.2 1.3 25 4 34.4a62.7 62.7 0 0 0 11 22.6 40
        40 0 0 0 16.3 12.3 53.8 53.8 0 0 0 20.1 3.7c5.7 0 11.5-.7
        17.3-2s10.4-3.5 13.9-6.4l-5-21.2Z"
      />
      <path
        id="o-3"
        d="M884.2 414.1a69 69 0 0 0 8.8 23c4 6.3 9.1 11.2 15.3 14.6a46.2 46.2 0
        0 0 22.5 5.1c16.4 0 28.8-6.2 37.1-18.6 8.3-12.4 12.5-30.5 12.5-54.2
        0-11-1-21.1-2.9-30.1-2-9-4.9-16.7-8.9-23a41.4 41.4 0 0 0-37.8-19.7c-16.7
        0-29 6.2-37.2 18.6-8.1 12.4-12.2 30.5-12.2 54.2 0 11 1 21.1 2.8
        30.1Zm46.6-79.7c4.4 0 8 1.3 11 3.9 3 2.6 5.3 6.1 7.1 10.6 1.8 4.5 3.1
        9.7 3.9 15.8.8 6 1.2 12.5 1.2 19.3 0 17.3-2 30-5.8 37.8-3.9 7.9-9.7
        11.8-17.4 11.8-4.3 0-7.8-1.3-10.7-3.8-2.9-2.5-5.2-6-7.1-10.4a60.4 60.4 0
        0 1-4-15.7 151 151 0 0 1-1.2-19.7c0-33 7.7-49.6 23-49.6Z"
      />
      <path
        id="L"
        d="M851.2 430.8H802V314h-25.2v140h74.4v-23.2z"
      />
    </g>
  </svg>
);

export const Sustainable = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2100 1152">
    {WITHOUT_BOILERS}
    <g id="Sustainable_3_Outlines">
      <path
        id="e-2"
        d="M1556.9
        454h70.8v-23.2h-45.6v-36h40.6v-23.2h-40.6v-34.4h44.8V314h-70v140z"
      />
      <path
        id="l-2"
        d="M1528.5 430.8h-49.2V314h-25.2v140h74.4v-23.2z"
      />
      <path
        id="b"
        d="M1414.5
        329.1c-2.4-4.5-5.6-8-9.7-10.5-4-2.5-8.8-4.3-14.2-5.2-5.4-1-11-1.4-16.9-1.4a208.9
        208.9 0 0 0-35.6 3v138c3 .8 6.4 1.4 10 1.8l10.5.9 9.5.3h7.2c6.3 0
        12.2-.8 17.8-2.4 5.6-1.6 10.5-4 14.7-7.4 4.2-3.3 7.5-7.6 10-12.9a43.7
        43.7 0 0 0 3.7-18.7c0-5.3-.6-10-1.9-13.9-1.3-4-3-7.3-5.1-10a24.8 24.8 0
        0 0-7.5-6.5 37 37 0 0 0-9.1-3.6v-1.2a26.9 26.9 0 0 0 15.6-13.1c1.6-3
        2.8-6.2 3.5-9.6a49 49 0 0 0 1.1-10.3c0-7-1.2-12.8-3.6-17.3Zm-45.7
        104.8c-2.6-.2-4.4-.5-5.5-.9v-39.8h10.2c2.8 0 5.5.2 8.1.6a14.6 14.6 0 0 1
        11.7 8.7c1.2 2.6 1.8 6.1 1.8 10.5 0 7-1.8 12.2-5.4 15.8a18.7 18.7 0 0
        1-13.8 5.4l-7.1-.3Zm-5.5-60.5v-38.8a57.8 57.8 0 0 1 18.7.1c2.2.6 4.1 1.6
        5.7 3 1.6 1.4 2.9 3.3 3.8 5.7 1 2.4 1.4 5.3 1.4 8.8 0 5.3-1.4 9.7-4.1
        13-2.7 3.3-6 5.7-9.9 7.2a42 42 0 0 1-9.8 1h-5.8Z"
      />
      <path
        id="a"
        d="M1285.1 454h26.4l-40.8-141h-19.8l-40.6 141h25l8.2-32h33l8.6
        32Zm-28.4-84.8 3.4-22.2h.8l3.2 22 8 32.6h-23.2l7.8-32.4Z"
      />
      <path
        id="n"
        d="M1164.5 455h19.4V314h-23.8v69.8l3.2
        23.8h-1.2l-8.6-24.2-40.2-70.4h-19.4v141h23.8v-69.2l-3.2-24.2h1l9.2 24.6
        39.8 69.8z"
      />
      <path
        id="i-3"
        d="M1030.5 314h25.2v140h-25.2z"
      />
      <path
        id="a-2"
        d="M975.3 454h26.4l-40.8-141h-19.8l-40.6 141h25l8.2-32h33l8.6
        32Zm-28.4-84.8 3.4-22.2h.8l3.2 22 8 32.6h-23.2l7.8-32.4Z"
      />
      <path
        id="t-3"
        d="M894.7 314h-91.4v23.2h33.2V454h25.2V337.2h33V314z"
      />
      <path
        id="s-2"
        d="M733.9 433.6a46 46 0 0 1-15-2.4 59.4 59.4 0 0 1-11-4.8l-8.2 22.6c3.3
        2 8.1 3.8 14.3 5.4a87.4 87.4 0 0 0 21.7 2.4c6.5 0 12.5-.9 17.8-2.6a33.7
        33.7 0 0 0 22.5-20.6c2-5.2 3.1-11.3 3.1-18.4s-1.3-13.2-3.8-18.3a50.1
        50.1 0 0 0-9.5-13.4c-3.8-3.8-8-7.1-12.4-10a359 359 0 0
        1-12.4-8.3c-3.8-2.7-7-5.4-9.5-8.3a14.5 14.5 0 0 1-3.8-9.9c0-4.1 1.4-7.3
        4.2-9.4 2.8-2.1 6.8-3.2 12-3.2a50.6 50.6 0 0 1 24.6
        6.4l7.8-22.2c-3.9-2.3-8.7-4-14.6-5.4-5.9-1.3-12.2-2-19-2-5.6
        0-10.9.7-15.8 2.1a31 31 0 0 0-21.3 18.2 43 43 0 0 0-3.1 17.3 41.4 41.4 0
        0 0 13.3 31.8c3.8 3.7 8 7 12.4 9.8 4.5 2.8 8.6 5.5 12.4 8.2 3.8 2.7 7
        5.5 9.5 8.5s3.8 6.6 3.8 10.9c0 10.4-6.7 15.6-20 15.6Z"
      />
      <path
        id="u-2"
        d="M645.7 403.8c0 10.4-1.4 17.9-4.2 22.5-2.8 4.6-7.5 6.9-14
        6.9s-11.7-2.2-14.6-6.5c-3-4.3-4.4-12-4.4-22.9V314h-25.2v94.2c0 16.3 3.6
        28.4 10.7 36.3 7.1 8 18.4 11.9 33.7 11.9 13 0 23.3-4 30.7-12.2 7.4-8.1
        11.1-21.3 11.1-39.4V314h-23.8v89.8Z"
      />
      <path
        id="S"
        d="M507.1 433.6a46 46 0 0 1-15-2.4 59.4 59.4 0 0 1-11-4.8l-8.2 22.6c3.3
        2 8.1 3.8 14.3 5.4a87.4 87.4 0 0 0 21.7 2.4c6.5 0 12.5-.9 17.8-2.6a33.7
        33.7 0 0 0 22.5-20.6c2-5.2 3.1-11.3 3.1-18.4s-1.3-13.2-3.8-18.3a50.1
        50.1 0 0 0-9.5-13.4c-3.8-3.8-8-7.1-12.4-10a359 359 0 0
        1-12.4-8.3c-3.8-2.7-7-5.4-9.5-8.3a14.5 14.5 0 0 1-3.8-9.9c0-4.1 1.4-7.3
        4.2-9.4 2.8-2.1 6.8-3.2 12-3.2a50.6 50.6 0 0 1 24.6
        6.4l7.8-22.2c-3.9-2.3-8.7-4-14.6-5.4-5.9-1.3-12.2-2-19-2-5.6
        0-10.9.7-15.8 2.1a31 31 0 0 0-21.3 18.2 43 43 0 0 0-3.1 17.3 41.4 41.4 0
        0 0 13.3 31.8c3.8 3.7 8 7 12.4 9.8 4.5 2.8 8.6 5.5 12.4 8.2 3.8 2.7 7
        5.5 9.5 8.5s3.8 6.6 3.8 10.9c0 10.4-6.7 15.6-20 15.6Z"
      />
    </g>
  </svg>
);

export const Better = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2100 1152">
    {WITHOUT_BOILERS}
    <g id="Better_2_Outlines">
      <path
        id="r-2"
        className="cls-1"
        d="M1282.2 453.2h25.2v-57.8l10.8 2.6 24.4 55.2h29l-25.8-53.6-8-6.4a32.7
        32.7 0 0 0 17.3-14.7 54 54 0 0 0 6.3-27.7 43 43 0 0
        0-3.9-19.5c-2.6-5.1-6-9.2-10.2-12.2s-9-5-14.5-6.2c-5.5-1.1-11-1.7-16.8-1.7a196.5
        196.5 0 0 0-33.8 3.4v138.6Zm25.2-117.8c1.2-.4 2.8-.7 4.8-.8l6.2-.2c5.7 0
        10 1.9 12.6 5.7a25 25 0 0 1 4 14.7c0 8-1.6 13.9-4.8 17.7-3.2 3.8-8
        5.7-14.2 5.7h-8.6v-42.8Z"
      />
      <path
        id="e-2"
        className="cls-1"
        d="M1178.2
        453.2h70.8V430h-45.6v-36h40.6v-23.2h-40.6v-34.4h44.8v-23.2h-70v140z"
      />
      <path
        id="t-3"
        className="cls-1"
        d="M1149.6 313.2h-91.4v23.2h33.2v116.8h25.2V336.4h33v-23.2z"
      />
      <path
        id="t-4"
        className="cls-1"
        d="M1033 313.2h-91.4v23.2h33.2v116.8h25.2V336.4h33v-23.2z"
      />
      <path
        id="e-3"
        className="cls-1"
        d="M845
        453.2h70.8V430h-45.6v-36h40.6v-23.2h-40.6v-34.4H915v-23.2h-70v140z"
      />
      <path
        id="B-2"
        className="cls-1"
        d="M805.4
        328.3c-2.4-4.5-5.6-8-9.7-10.5-4-2.5-8.8-4.3-14.2-5.2-5.4-1-11-1.4-16.9-1.4a208.9
        208.9 0 0 0-35.6 3v138c3 .8 6.4 1.4 10 1.8l10.5.9 9.5.3h7.2c6.3 0
        12.2-.8 17.8-2.4 5.6-1.6 10.5-4 14.7-7.4 4.2-3.3 7.5-7.6 10-12.9a43.7
        43.7 0 0 0 3.7-18.7c0-5.3-.6-10-1.9-13.9-1.3-4-3-7.3-5.1-10a24.8 24.8 0
        0 0-7.5-6.5 37 37 0 0 0-9.1-3.6v-1.2a26.9 26.9 0 0 0 15.6-13.1c1.6-3
        2.8-6.2 3.5-9.6a49 49 0 0 0 1.1-10.3c0-7-1.2-12.8-3.6-17.3Zm-45.7
        104.8c-2.6-.2-4.4-.5-5.5-.9v-39.8h10.2c2.8 0 5.5.2 8.1.6a14.6 14.6 0 0 1
        11.7 8.7c1.2 2.6 1.8 6.1 1.8 10.5 0 7-1.8 12.2-5.4 15.8a18.7 18.7 0 0
        1-13.8 5.4l-7.1-.3Zm-5.5-60.5v-38.8a57.8 57.8 0 0 1 18.7.1c2.2.6 4.1 1.6
        5.7 3 1.6 1.4 2.9 3.3 3.8 5.7 1 2.4 1.4 5.3 1.4 8.8 0 5.3-1.4 9.7-4.1
        13-2.7 3.3-6 5.7-9.9 7.2a42 42 0 0 1-9.8 1h-5.8Z"
      />
    </g>
  </svg>
);

export const Affordable = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2100 1152">
    {WITHOUT_BOILERS}
    <g id="Affordable_2_Outlines">
      <path
        id="e-2"
        d="M1532.8
        454h70.8v-23.2H1558v-36h40.6v-23.2H1558v-34.4h44.8V314h-70v140z"
      />
      <path
        id="l-2"
        d="M1504.4 430.8h-49.2V314H1430v140h74.4v-23.2z"
      />
      <path
        id="b"
        d="M1390.4
        329.1c-2.4-4.5-5.6-8-9.7-10.5-4-2.5-8.8-4.3-14.2-5.2-5.4-1-11-1.4-16.9-1.4a208.9
        208.9 0 0 0-35.6 3v138c3 .8 6.4 1.4 10 1.8a203.2 203.2 0 0 0 20
        1.2h7.2c6.3 0 12.2-.8 17.8-2.4 5.6-1.6 10.5-4 14.7-7.4 4.2-3.3 7.5-7.6
        10-12.9a43.7 43.7 0 0 0
        3.7-18.7c0-5.3-.6-10-1.9-13.9-1.3-4-3-7.3-5.1-10a24.8 24.8 0 0 0-7.5-6.5
        37 37 0 0 0-9.1-3.6v-1.2a26.9 26.9 0 0 0 15.6-13.1c1.6-3 2.8-6.2
        3.5-9.6a49 49 0 0 0 1.1-10.3c0-7-1.2-12.8-3.6-17.3Zm-45.7
        104.8c-2.6-.2-4.4-.5-5.5-.9v-39.8h10.2c2.8 0 5.5.2 8.1.6a14.6 14.6 0 0 1
        11.7 8.7c1.2 2.6 1.8 6.1 1.8 10.5 0 7-1.8 12.2-5.4 15.8a18.7 18.7 0 0
        1-13.8 5.4l-7.1-.3Zm-5.5-60.5v-38.8a57.8 57.8 0 0 1 18.7.1c2.2.6 4.1 1.6
        5.7 3 1.6 1.4 2.9 3.3 3.8 5.7 1 2.4 1.4 5.3 1.4 8.8 0 5.3-1.4 9.7-4.1
        13-2.7 3.3-6 5.7-9.9 7.2a42 42 0 0 1-9.8 1h-5.8Z"
      />
      <path
        id="a"
        d="M1261 454h26.4l-40.8-141h-19.8l-40.6 141h25l8.2-32h33l8.6
        32Zm-28.4-84.8 3.4-22.2h.8l3.2 22 8 32.6h-23.2l7.8-32.4Z"
      />
      <path
        id="d"
        d="M1075.6 454.2a1611.7 1611.7 0 0 0 32.2 2c11.7 0 21.5-2 29.2-6 7.7-4
        13.9-9.4 18.4-16.1a64.3 64.3 0 0 0 9.5-23.4 140 140 0 0 0
        2.7-27.9c0-10.7-1-20.3-2.8-29-1.9-8.7-5-16.1-9.5-22.3a44.2 44.2 0 0
        0-17.8-14.4 66.3 66.3 0 0 0-27.7-5.1 328.3 328.3 0 0 0-34.2
        2v140.2Zm51.5-115.1c3.8 2.6 6.7 6 8.8 10.4 2 4.3 3.5 9.4 4.2 15.1a140.8
        140.8 0 0 1-.2 36.9c-.9 6-2.4 11.4-4.6 16.1a28.5 28.5 0 0 1-9.1 11.2
        24.6 24.6 0 0 1-16.9 4.1l-3.2-.2-3.2-.2c-1 0-1.7-.2-2.1-.3v-96.4a29 29 0
        0 1 5.1-.5l6.5-.1c6 0 10.9 1.3 14.7 3.9Z"
      />
      <path
        id="r-2"
        d="M957 454h25.2v-57.8l10.8 2.6 24.4 55.2h29l-25.8-53.6-8-6.4a32.7 32.7
        0 0 0 17.3-14.7 54 54 0 0 0 6.3-27.7 43 43 0 0
        0-3.9-19.5c-2.6-5.1-6-9.2-10.2-12.2s-9-5-14.5-6.2c-5.5-1.1-11-1.7-16.8-1.7a196.5
        196.5 0 0 0-33.8 3.4V454Zm25.2-117.8c1.2-.4 2.8-.7 4.8-.8l6.2-.2c5.7 0
        10 1.9 12.6 5.7a25 25 0 0 1 4 14.7c0 8-1.6 13.9-4.8 17.7-3.2 3.8-8
        5.7-14.2 5.7h-8.6v-42.8Z"
      />
      <path
        id="o-3"
        d="M828 414.1a69 69 0 0 0 8.8 23c4 6.3 9.1 11.2 15.3 14.6a46.2 46.2 0 0
        0 22.5 5.1c16.4 0 28.8-6.2 37.1-18.6 8.3-12.4 12.5-30.5 12.5-54.2
        0-11-1-21.1-2.9-30.1-2-9-4.9-16.7-8.9-23a41.4 41.4 0 0 0-37.8-19.7c-16.7
        0-29 6.2-37.2 18.6-8.1 12.4-12.2 30.5-12.2 54.2 0 11 1 21.1 2.8
        30.1Zm46.6-79.7c4.4 0 8 1.3 11 3.9 3 2.6 5.3 6.1 7.1 10.6 1.8 4.5 3.1
        9.7 3.9 15.8.8 6 1.2 12.5 1.2 19.3 0 17.3-2 30-5.8 37.8-3.9 7.9-9.7
        11.8-17.4 11.8-4.3 0-7.8-1.3-10.7-3.8-2.9-2.5-5.2-6-7.1-10.4a60.4 60.4 0
        0 1-4-15.7 151 151 0 0 1-1.2-19.7c0-33 7.7-49.6 23-49.6Z"
      />
      <path
        id="f"
        d="M726.6 454h25.2v-57.2H793v-23.2h-41.2v-36.4h44.8V314h-70v140z"
      />
      <path
        id="f-2"
        d="M624.8 454H650v-57.2h41.2v-23.2H650v-36.4h44.8V314h-70v140z"
      />
      <path
        id="A"
        d="M571.8 454h26.4l-40.8-141h-19.8L497 454h25l8.2-32h33l8.6
        32Zm-28.4-84.8 3.4-22.2h.8l3.2 22 8 32.6h-23.2l7.8-32.4Z"
      />
    </g>
  </svg>
);

export const Healthy = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2100 1152">
    {WITHOUT_BOILERS}
    <g id="Healthy_2_Outlines">
      <path
        id="y"
        d="M1365.1 452.2h25.2V400l37.8-87.8h-27l-19 52.6-2.2
        15.6h-1l-2-15.2-19.4-53h-30l37.6 88v52z"
      />
      <path
        id="h-2"
        d="M1276.1
        452.2h25.2v-140h-25.2v57.6h-38.2v-57.6h-25.2v140h25.2V393h38.2v59.2z"
      />
      <path
        id="t-3"
        d="M1184.1 312.2h-91.4v23.2h33.2v116.8h25.2V335.4h33v-23.2z"
      />
      <path
        id="l-2"
        d="M1093.7 429h-49.2V312.2h-25.2v140h74.4V429z"
      />
      <path
        id="a"
        d="M966.3 452.2h26.4l-40.8-141h-19.8l-40.6 141h25l8.2-32h33l8.6
        32Zm-28.4-84.8 3.4-22.2h.8l3.2 22 8 32.6h-23.2l7.8-32.4Z"
      />
      <path
        id="e-2"
        d="M797.1
        452.2h70.8V429h-45.6v-36h40.6v-23.2h-40.6v-34.4h44.8v-23.2h-70v140z"
      />
      <path
        id="H"
        d="M735.9
        452.2h25.2v-140h-25.2v57.6h-38.2v-57.6h-25.2v140h25.2V393h38.2v59.2z"
      />
    </g>
  </svg>
);

export default {
  Local, Healthy, Affordable, Sustainable, Better,
};
