import React from 'react';

import './icon-with-stat-list.scss';

interface IconWithStatListProps {
  icon: React.ReactNode;
  stats: {
    value: string;
    label: string;
  }[];
  color: string;
}

const IconWithStatList = ({ icon, stats, color } : IconWithStatListProps) => (
  <div className={`icon-with-stat-list icon-with-stat-list--color-${color}`}>
    <div className="icon-with-stat-list__icon">
      {icon}
    </div>
    <div className={`icon-with-stat-list__stats icon-with-stat-list__stats--count-${stats.length}`}>
      {stats.map((stat) => (
        <div className="icon-with-stat-list__stat" key={stat.label}>
          <div className="icon-with-stat-list__stat-value">
            {stat.value}
          </div>
          <div className="icon-with-stat-list__stat-label">
            {stat.label}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default IconWithStatList;
