export interface CountryEntry {
  Country: string;
}

export interface CountryCollection {
  [key: string]: CountryEntry;
}

const EUROPE_COUNTRY_CODES: { [key: string]: string } = {
  Germany: 'DE',
  France: 'FR',
  Italy: 'IT',
  Spain: 'ES',
  'United Kingdom': 'GB',
  Netherlands: 'NL',
  Belgium: 'BE',
  Austria: 'AT',
  Switzerland: 'CH',
  Sweden: 'SE',
  Norway: 'NO',
  Denmark: 'DK',
  Finland: 'FI',
  Ireland: 'IE',
  Portugal: 'PT',
  Greece: 'GR',
  'Czech Republic': 'CZ',
  Poland: 'PL',
  Hungary: 'HU',
  Romania: 'RO',
  Bulgaria: 'BG',
  Croatia: 'HR',
  Slovenia: 'SI',
  Slovakia: 'SK',
  Lithuania: 'LT',
  Latvia: 'LV',
  Estonia: 'EE',
  Cyprus: 'CY',
  Malta: 'MT',
  Luxembourg: 'LU',
  Iceland: 'IS',
  Liechtenstein: 'LI',
  Monaco: 'MC',
  'The Netherlands': 'NL',
};

export const countryArrayToObject = (array: CountryEntry[]) => {
  const countries = {} as CountryCollection;
  array.forEach((item) => {
    countries[item.Country] = item;
  });

  return countries;
};

export const contryNameToCode = (name: string) => {
  if (name in EUROPE_COUNTRY_CODES) {
    return EUROPE_COUNTRY_CODES[name];
  }
  return name;
};

export const sortByKey = (key: string) => (a: any, b: any) => {
  const aVal = parseFloat(a[key]);
  const bVal = parseFloat(b[key]);
  if (aVal > bVal) {
    return -1;
  }
  if (aVal < bVal) {
    return 1;
  }
  return 0;
};

export default { countryArrayToObject };
