import React, { useState } from 'react';

import './read-more.scss';

interface ReadMoreProps {
  title: string
  content: React.ReactNode
  image: React.ReactNode,
  readMoreLabel: string
}

const ReadMore = ({
  title, content, image, readMoreLabel,
}: ReadMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`read-more${isExpanded ? ' is-expanded' : ''}`}>
      <div className="read-more__hero">
        <div className="read-more__image">{image}</div>
        <div className="read-more__title">{title}</div>
      </div>
      <div className="read-more__content">
        {content}
      </div>
      {!isExpanded ? (
        <button type="button" onClick={() => setIsExpanded(true)} className="read-more__button">
          {readMoreLabel}
        </button>
      ) : ''}
    </div>
  );
};

export default ReadMore;
