import React, { useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { parse } from 'csv-parse/browser/esm/sync';
import { AntiGas, Flame, AntiBoiler } from '../icons';
import BoxWithLabel from '../box-with-label';
import DiagramWrapper from '../diagram-wrapper';

import './gas-imports-diagram.scss';
import BarDiagram from '../bar-diagram';
import CountryContext from '../country-context';
import IconWithLabel from '../icon-with-label';
import ValueWithLabel from '../value-with-label';
import { countryArrayToObject, contryNameToCode, sortByKey } from '../../utils/country-data';

const DATA_FILE = 'Data-imports.csv';

const SORT_KEY = 'Gas consumption for heating (TWh, in 2017)';

const GasImportsDiagram = ({ getText }: { getText: TextFunction.textContentFunction}) => {
  const { country } = useContext(CountryContext);
  const countryData = useMemo(() => {
    const data = parse(getText(DATA_FILE, { stringOnly: true }) as string, {
      columns: true,
      skip_empty_lines: true,
    }).sort(sortByKey(SORT_KEY));

    return data;
  }, [getText]);

  const countryDataDict: any = useMemo(() => countryArrayToObject(countryData), [countryData]);

  const processedData = useMemo(() => countryData.map((d: any) => {
    const consumption = parseInt(d['Gas consumption for heating (TWh, in 2017)'].replace(',', ''), 10);
    const savings = parseInt(d['2030 gas savings with a ban on new boilers in 2025 (TWh)'], 10);
    return {
      country: d.Country,
      name: contryNameToCode(d.Country),
      value: [consumption, consumption - savings],
    };
  }).filter((d: any) => d.country !== 'EU' && d.value[0] !== 0), [countryData]);
  return (
    <div className="gas-imports-diagram">
      <DiagramWrapper
        orientation="vertical"
        title={getText('Will moving away from boilers save gas?', { stringOnly: true })}
        description={getText('How would the shift away from boilers deliver gas savings?')}
      >
        <BarDiagram data={processedData} />
        <div className="gas-imports-diagram__notes">
          <div className="gas-imports-diagram__keys">
            <div
              className="gas-imports-diagram__key gas-imports-diagram__key--b"
            >
              {getText('2030 gas savings with a ban on new boilers in 2025 (TWh)', { stringOnly: true })}
            </div>
            <div
              className="gas-imports-diagram__key gas-imports-diagram__key--a"
            >
              {getText('Gas consumption for heating (TWh, in 2017)', { stringOnly: true })}
            </div>
          </div>
          <div>
            {getText('How would the shift away from boilers deliver gas savings? Under "Across the EU" box', { stringOnly: true })}
          </div>
        </div>
        <div>
          <BoxWithLabel
            label={getText(country, { stringOnly: true }) || getText('Gas imports', { stringOnly: true })}
            color={country ? null : 'yellow'}
            icon={country ? null : AntiGas}
          >
            {country ? (
              <div className="gas-imports-diagram__details-container">
                <div className="gas-imports-diagram__details">
                  <IconWithLabel
                    icon={Flame}
                    label={`${countryDataDict[country]['Gas consumption for heating (TWh, in 2017)']} TWh`}
                    color="yellow"
                    description={getText('Gas consumption for heating (TWh, in 2017)', { stringOnly: true })}
                  />
                  <IconWithLabel
                    icon={AntiBoiler}
                    label={`${countryDataDict[country]['2030 gas savings with a ban on new boilers in 2025 (TWh)']} TWh`}
                    color="red"
                    description={getText('2030 gas savings with a ban on new boilers in 2025 (TWh)', { stringOnly: true })}
                  />
                  <ValueWithLabel
                    value={countryDataDict[country]['Gas savings equivalent to Russian imports (2020)'] || 'N/A'}
                    label={getText('Gas savings equivalent to Russian imports (2020)', { stringOnly: true })}
                    color="red"
                  />
                  <ValueWithLabel
                    value={countryDataDict[country]['Gas savings with a ban on new boilers from 2025 compared to overall gas consumption'] || 'N/A'}
                    label={getText('Gas savings with a ban on new boilers from 2025 compared to overall gas consumption', { stringOnly: true })}
                    color="red"
                  />
                </div>
              </div>
            ) : (
              getText('How would the shift away from boilers deliver gas savings? "Across the EU" box')
            )}
          </BoxWithLabel>
        </div>
      </DiagramWrapper>
    </div>
  );
};

export default GasImportsDiagram;
