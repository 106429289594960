import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Better, Healthy, Affordable, Sustainable, Local,
} from './signage';

import './hero-animation.scss';

const HeroAnimation = () => (
  <div className="hero-animation">
    <div className="hero-animation__images">
      <div className="hero-animation__image">
        <StaticImage src="images/1.jpg" alt="" width={1920} />
        { Better }
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/2.jpg" alt="" width={1920} />
        { Better }
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/3.jpg" alt="" width={1920} />
        {Healthy}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/4.jpg" alt="" width={1920} />
        {Healthy}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/5.jpg" alt="" width={1920} />
        {Affordable}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/6.jpg" alt="" width={1920} />
        {Affordable}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/7.jpg" alt="" width={1920} />
        {Sustainable}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/8.jpg" alt="" width={1920} />
        {Sustainable}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/9.jpg" alt="" width={1920} />
        {Local}
      </div>
      <div className="hero-animation__image">
        <StaticImage src="images/10.jpg" alt="" width={1920} />
        {Local}
      </div>
    </div>
  </div>
);

export default HeroAnimation;
