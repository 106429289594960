import React, { useRef, useEffect, useContext } from 'react';
import colorBetween from 'color-between';
import * as d3 from 'd3';

import CountryContext from '../country-context';
import TabbedContext from '../tabbed-context';

import './map.scss';
import ScaleBar from '../scale-bar';
import { BackButton } from '../icons';

const GEO_URL = '/europe.geojson';

const MAP_WIDTH = 500;
const MAP_HEIGHT = 400;
const DEFAULT_GREY = '#E5E5E5';

const PROJECTION = d3.geoMercator()
  .center([8, 53])
  .scale(MAP_WIDTH / 1.14)
  .translate([MAP_WIDTH / 2, MAP_HEIGHT / 2]);

interface CountryNode {
  properties: {
    name: string
  }
}

interface CountryData {
  [key: string]: number | string
}

export interface TabEntry {
  name: string
  description: string
  icon: React.ReactNode
  color: 'red' | 'green' | 'blue' | 'yellow' | 'magenta'
}

interface MapProps {
  countryData: CountryData
  tabs?: TabEntry[]
  startColor?: string
  endColor?: string
  highlightColor?: string
  showBar?: boolean
  overviewLabel: string
}

const getValueForCountry = (
  country: string,
  data: CountryData,
  startColor: string,
  endColor: string,
  max: number,
  min: number = 0,
) => {
  if (country in data) {
    const value = data[country];
    if (typeof value === 'number') {
      return colorBetween(
        startColor, endColor, (value - min) / (max - min), 'hex',
      );
    }
    return value as string;
  }
  return DEFAULT_GREY;
};

const getMaxValue = (data: CountryData) => Object.values(data).reduce(
  (max, value) => Math.max(
    typeof max === 'number' ? max : 0,
    typeof value === 'number' ? value : 0,
  ), 0,
) as number;

const createMap = async (
  svg: any, countryData: CountryData, startColor: string, endColor: string,
) => {
  const data = await d3.json(GEO_URL) as {features: any};
  const max = getMaxValue(countryData);
  svg.append('g')
    .selectAll('path')
    .data(data.features)
    .join('path')
    .attr('fill', ({ properties: { name } }: CountryNode) => (
      getValueForCountry(name, countryData, startColor, endColor, max)))
    .attr('d', d3.geoPath().projection(PROJECTION))
    .attr('tabindex', 0)
    .attr('name', ({ properties: { name } }: CountryNode) => name);
};

const updateValues = (
  svg: SVGSVGElement,
  countryData: CountryData,
  startColor: string,
  endColor: string,
  max: number,
  activeCountry: string,
  highlightColor: string,
) => {
  svg.querySelectorAll('path').forEach((path) => {
    const country = path.getAttribute('name');
    let targetFill = DEFAULT_GREY;
    if (country === activeCountry) {
      targetFill = highlightColor
        || getValueForCountry(country, countryData, startColor, endColor, max);
    } else if (!activeCountry) {
      targetFill = getValueForCountry(
        country || '', countryData, startColor, endColor, max,
      );
    }
    // eslint-disable-next-line no-param-reassign
    path.style.fill = targetFill;
  });
};

const MapDiagram = ({
  countryData, tabs, startColor, endColor, showBar, highlightColor, overviewLabel,
}: MapProps) => {
  const ref = useRef(null as unknown as SVGSVGElement);
  const { country, setCountry } = useContext(CountryContext);
  const { activeTab, setActiveTab } = useContext(TabbedContext);

  useEffect(() => {
    createMap(
      d3.select(ref.current), countryData, startColor || '', endColor || '',
    );

    const handleClick = (event: MouseEvent) => {
      const target = event.target as SVGPathElement;
      if (target.tagName === 'path') {
        setCountry(target.getAttribute('name') || '');
      }
    };

    ref.current.addEventListener('click', handleClick);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('click', handleClick);
        ref.current.innerHTML = '';
      }
    };
  }, []);

  useEffect(() => {
    const max = getMaxValue(countryData);
    updateValues(
      ref.current,
      countryData,
      startColor || '',
      endColor || '',
      max,
      country,
      highlightColor || '',
    );
  }, [countryData, startColor, endColor, country, highlightColor]);

  const max = getMaxValue(countryData);

  const activeTabColor = tabs ? tabs[activeTab].color : '';
  const activeTabDescription = tabs ? tabs[activeTab].description : '';

  return (
    <div className="map">
      <div className="map-diagram-container">
        <svg
          className="map-diagram"
          preserveAspectRatio="xMinYMin meet"
          ref={ref}
          viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`}
        />
        { showBar && (
          <ScaleBar
            segmentCount={10}
            startColor={startColor || ''}
            endColor={endColor || ''}
            startNumber={0}
            endNumber={max}
          />
        ) }
        { country && (
          <button
            className="map__back-button"
            type="button"
            onClick={() => setCountry('')}
          >
            {BackButton}
            {' '}
            {overviewLabel}
          </button>
        ) }
      </div>
      <div className={`map__active-tab map__active-tab--color-${activeTabColor}`}>
        {activeTabDescription}
      </div>
      <div className="map-tabs">
        { tabs && tabs.map(({ name, icon, color }, index) => (
          <button
            className={
              `map-tab map-tab--color-${color}${activeTab === index ? ' is-active' : ''}`
            }
            key={name}
            onClick={() => setActiveTab(index)}
            tabIndex={0}
            type="button"
          >
            <div className="map-tab-icon">{icon}</div>
            <div className="map-tab-name">{name}</div>
          </button>
        ))}
      </div>
    </div>
  );
};

MapDiagram.defaultProps = {
  startColor: '#f00',
  endColor: '#0f0',
  highlightColor: '#ff0',
  showBar: false,
  tabs: [],
};

export default MapDiagram;
