import React from 'react';

import './box-with-label.scss';

interface BoxWithLabelProps {
  label: string;
  color: string | null;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const BoxWithLabel = ({
  label, children, icon, color,
}: BoxWithLabelProps) => (
  <div className={`box-with-label box-with-label--color-${color}`}>
    <div className="box-with-label__icon">
      {icon}
    </div>
    <div className="box-with-label__label">{label}</div>
    <div className="box-with-label__content">{children}</div>
  </div>
);

export default BoxWithLabel;
