import React from 'react';

import './value-with-label.scss';

interface ValueWithLabelProps {
  value: string;
  label: string;
  color: string;
}

const ValueWithLabel = ({ value, label, color } : ValueWithLabelProps) => (
  <div className={`value-with-label value-with-label--color-${color}`}>
    <div className="value-with-label__value">
      {value}
    </div>
    <div className="value-with-label__label">
      {label}
    </div>
  </div>
);

export default ValueWithLabel;
