import React from 'react';

import './icon-with-label.scss';

interface IconWithLabelProps {
  icon: React.ReactNode;
  label: string;
  color: string;
  description: string;
}

const IconWithLabel = ({
  icon, label, color, description,
} : IconWithLabelProps) => (
  <div className={`icon-with-label icon-with-label--color-${color}`}>
    <div className="icon-with-label__icon">
      {icon}
    </div>
    <div className="icon-with-label__label">
      {label}
    </div>
    <div className="icon-with-label__description">
      {description}
    </div>
  </div>
);

export default IconWithLabel;
